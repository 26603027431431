import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

export default function MSViewer({ title, src, height = '75vh', marginTop = 15 }) {
  return (
    <Container maxWidth={false} disableGutters>
      <Box
        component="iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(src)}`}
        title={title}
        sx={{
          width: '100%',
          border: '1px solid #ccc',
          height: {
            xs: '65vh',
            sm: '65vh',
            md: '65vh',
            lg: '65vh',
            xl: height,
          },
          marginTop: `${marginTop}px`,
        }}
      />
    </Container>
  );
}
