import React, { useState, useEffect, useRef } from 'react';
import { grey, blue, red, green } from '@mui/material/colors';
import { Autocomplete, FormControlLabel } from '@mui/material';
import { CircularProgress, Grid, Popover, Skeleton, TextField, IconButton } from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Menu, Button } from '@mui/material';
import { CheckBoxOutlineBlankRounded, CheckBoxRounded, ArrowDropDownRounded } from '@mui/icons-material';
import { CloseRounded, SearchRounded, DeleteOutlineRounded } from '@mui/icons-material';
import { CheckCircleRounded, CheckCircleOutlineRounded, ListRounded, ChecklistRounded } from '@mui/icons-material';
import { TooltipTypography, DebouncedTextField, UserGroup } from '../Components';
import { Typography } from '@mui/material';
import { Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import StateManager from '../StateManager';
import axios from 'axios';
import { isArray, isEmpty, range } from 'lodash';
import { useSelector } from 'react-redux';

const noValue = <Typography style={{ fontSize: 14, color: red[400] }}>No value provided</Typography>;

const PAGE_SIZE = 20;

export default function DataSetField({ params, onResult, value, editable = true }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowsIds = selectedRows.map((x) => x.id);

  const [menuAnchor, setMenuAnchor] = useState(null);

  const { user } = useSelector(({ profile }) => profile);

  const access = user?.access;

  useEffect(() => {
    if (!isArray(value?.rows)) return;

    setSelectedRows(value.rows);
  }, [value]);

  // no columns defined - unable to display
  if (!isArray(params?.columns) || isEmpty(params?.columns)) return null;

  const { columns, setId, selectType, displayType } = params;

  // === NOT EDITABLE (DISPLAY VALUE) ===
  if (!editable) {
    // nothing selected
    if (!isArray(value?.rows) || isEmpty(value?.rows)) return noValue;

    const rows = value.rows;

    // link only for admins
    const link =
      access === 'admin' ? (
        <Link style={{ marginTop: 8, color: blue[700] }} to={`/data/view-set/${params.setId}`}>
          Go to the data set
        </Link>
      ) : null;

    // single column - display as list
    if (columns.length === 1) {
      const column = columns[0];
      return (
        <Grid container>
          {rows.map((row, i) => (
            <Grid container item sx={{ my: 0.5 }} key={i}>
              <DataSetCell column={column} value={row[column.id]} />
            </Grid>
          ))}
          {link}
        </Grid>
      );
    }

    // multiple columns
    return (
      <Grid container>
        <TableContainer style={{ overflow: 'auto' }}>
          <Table size="small" style={{ width: 'fit-content' }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    style={{ fontWeight: 700, borderBottom: `2px solid ${grey[700]}`, fontSize: 16 }}
                    key={column.id}
                  >
                    {column.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={`${column.id}#${row.id}`}>
                      <DataSetCell column={column} value={row[column.id]} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {link}
      </Grid>
    );
  }

  // === EDITABLE ===

  function handleRows(row) {
    if (!row) return;

    const rowId = row.id;

    let updatedSelectedRows = [];

    if (selectedRowsIds.includes(rowId)) {
      updatedSelectedRows = selectedRows.filter((x) => x.id !== rowId);
    } else {
      if (selectType === 'single') {
        updatedSelectedRows = [row];
      } else {
        updatedSelectedRows = [...selectedRows, row];
      }
    }

    const columnIds = columns.map((x) => x.id);

    const result = updatedSelectedRows.map((row) => ({
      id: row.id,
      ...columnIds.reduce((prev, columnId) => ({ ...prev, [columnId]: row[columnId] }), {}),
    }));

    setSelectedRows(result);

    onResult({ rows: result });

    // close the menu if single select
    if (selectType === 'single') {
      setMenuAnchor(null);
    }
  }

  // === display as dropdown (default) ===

  const SelectedData = !isEmpty(selectedRows) ? (
    <Grid container sx={{ mb: 1, overflow: 'auto' }}>
      <Table size="small" style={{ width: 'fit-content' }}>
        {columns.length > 1 && (
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.title}</TableCell>
              ))}
              <TableCell style={{ width: 40 }} />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {selectedRows.map((row) => (
            <TableRow key={row.id} hover>
              {columns.map((column) => (
                <TableCell key={`${column.id}#${row.id}`}>
                  <DataSetCell column={column} value={row[column.id]} />
                </TableCell>
              ))}
              <TableCell style={{ width: 40 }}>
                <IconButton size="small" onClick={() => handleRows(row)}>
                  <DeleteOutlineRounded style={{ color: red[500] }} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  ) : null;

  if (!displayType || displayType === 'dropdown') {
    return (
      <Grid container>
        <Grid container>
          <Button
            style={{
              textTransform: 'none',
              minWidth: 150,
              maxWidth: '100%',
              justifyContent: 'flex-start',
            }}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            startIcon={<ArrowDropDownRounded />}
          >
            <Typography noWrap sx={{ fontWeight: 500 }}>
              Pick option{selectType === 'multiple' ? 's' : ''}
            </Typography>
          </Button>

          <DataSetMenu
            anchorEl={menuAnchor}
            onClose={() => setMenuAnchor(null)}
            columns={columns}
            setId={setId}
            onResult={onResult}
            onRowClick={(rows, rowId) => handleRows(rows, rowId)}
            selectedRowsIds={selectedRowsIds}
          />
        </Grid>

        {SelectedData}
      </Grid>
    );
  }

  // === autocomplete ===
  // if there are more than 1 column picked, display as autocomplete too
  if (displayType === 'searchBar' || columns.length > 1) {
    return (
      <Grid container>
        <DataSetAutocomplete
          columns={columns}
          setId={setId}
          selectedRowsIds={selectedRowsIds}
          selectType={selectType}
          onRowClick={(row) => handleRows(row)}
        />
        {SelectedData}
      </Grid>
    );
  }

  if (displayType === 'tickbox' || displayType === 'buttons') {
    return (
      <DataSetTickboxes
        columns={columns}
        setId={setId}
        selectedRowsIds={selectedRowsIds}
        selectType={selectType}
        onRowClick={(row) => handleRows(row)}
        displayType={displayType}
      />
    );
  }
}

export function DataSetMenu({ anchorEl, onClose, setId, columns, onRowClick, selectedRowsIds }) {
  const [rows, setRows] = useState([]);
  const [totalCount, setTotaCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const [pattern, setPattern] = useState('');
  const [searchRows, setSearchRows] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [totalSearch, setTotalSearch] = useState(0);

  const [showSelected, setShowSelected] = useState(null);

  const searchMode = !isEmpty(String(pattern).trim());
  const rowsToDisplay = searchMode ? searchRows : rows;
  const total = searchMode ? totalSearch : totalCount;

  const loadedAll = searchMode ? searchRows.length >= totalSearch : rows.length >= totalCount;
  const loadFirstTime = anchorEl && isEmpty(rows);

  useEffect(() => {
    if (!loadFirstTime) return;

    loadRows(0);
  }, [loadFirstTime]); // eslint-disable-line

  useEffect(() => {
    if (showSelected == null) return;

    if (searchMode) {
      loadSearchRows(0, pattern);
    } else {
      loadRows(0);
    }
  }, [showSelected]); // eslint-disable-line

  function loadRows(skip, append) {
    if (loading) return;

    setLoading(true);

    const invitationId = localStorage.getItem('invitationId');
    const url = `${invitationId ? '/external' : ''}/data/getDataSetRows`;
    const body = {
      setId,
      invitationId,
      limit: PAGE_SIZE,
      skip,
      columnsIds: isArray(columns) ? columns.map((x) => x.id) : [],
      rowsIds: showSelected ? selectedRowsIds : null,
    };

    axios
      .post(url, body)
      .then(({ data }) => {
        setTotaCount(data.totalCount);
        setRows(append ? [...rows, ...data.rows] : data.rows);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
        setLoadingError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadSearchRows(skip, pattern, append) {
    if (!pattern) {
      setTotalSearch(0);
      setSearchRows([]);

      return;
    }

    setLoadingSearch(true);

    const invitationId = localStorage.getItem('invitationId');
    const url = `${invitationId ? '/external' : ''}/data/getDataSetRows`;
    const body = {
      setId,
      invitationId,
      limit: PAGE_SIZE,
      skip,
      columnsIds: isArray(columns) ? columns.map((x) => x.id) : [],
      pattern,
      rowsIds: showSelected ? selectedRowsIds : null,
    };

    axios
      .post(url, body)
      .then(({ data }) => {
        setTotalSearch(data.totalCount);
        setSearchRows(append ? [...searchRows, ...data.rows] : data.rows);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoadingSearch(false);
      });
  }

  const handleOnScroll = ({ target }) => {
    if (target.scrollTop + target.offsetHeight + 2 >= target.scrollHeight) {
      if (loadedAll) {
        // already loaded everything
        return;
      }
      if (searchMode) {
        loadSearchRows(searchRows.length, pattern, true);
      } else {
        loadRows(rows.length, true);
      }
    }
  };

  if (!isArray(columns) || isEmpty(columns)) return null;

  const columnWidth = columns.length === 1 ? 300 : 200;
  const checkboxColumnWidth = 50;

  const rowWidth = columns.length * columnWidth + checkboxColumnWidth;

  const minHeight = '30vh';
  const maxHeight = '60vh';
  const maxWidth = '80vw';

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      className="scroll-bar"
      sx={{
        '.MuiPopover-paper': { width: 'min-content', minWidth: 300, maxWidth },
      }}
    >
      <Grid container alignContent={'flex-start'} sx={{ p: 1 }}>
        {loading && isEmpty(rows) ? (
          range(10).map((i) => (
            <Grid container sx={{ my: 0.5 }} key={i}>
              <Skeleton width={'100%'} height={32} />
            </Grid>
          ))
        ) : isEmpty(rows) ? (
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: minHeight }}>
            <Typography color="textSecondary" sx={{ textAlign: 'center' }}>
              {loadingError ? 'Loading error occured. Please try reloading the page' : 'No rows'}
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid container sx={{ py: 1 }}>
              <DebouncedTextField
                variant="standard"
                placeholder="Search"
                fullWidth
                value={pattern}
                onValueChange={(value) => {
                  setPattern(value);
                  loadSearchRows(0, value);
                }}
                InputProps={{
                  startAdornment: loadingSearch ? (
                    <CircularProgress size={24} sx={{ mr: 1 }} />
                  ) : (
                    <SearchRounded sx={{ mr: 1 }} />
                  ),
                  endAdornment: pattern && (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setPattern('');
                        //setShowSelected(null);
                        loadSearchRows(0, '');
                      }}
                    >
                      <CloseRounded fontSize="small" />
                    </IconButton>
                  ),
                }}
                focused
                autoFocus
                id="search-menu"
                inputRef={(input) => input && input.focus()}
                debounceMs={400}
              />
            </Grid>

            {isEmpty(rowsToDisplay) && searchMode && (
              <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: minHeight }}>
                <Typography color="textSecondary">No rows match your search</Typography>
              </Grid>
            )}

            {!isEmpty(rowsToDisplay) && (
              <TableContainer sx={{ maxHeight, minHeight, maxWidth, overflow: 'auto' }} onScroll={handleOnScroll}>
                <Table stickyHeader size="small" sx={{ width: rowWidth }}>
                  {columns.length > 1 && (
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: checkboxColumnWidth }} />
                        {columns.map((column) => (
                          <TableCell sx={{ width: columnWidth }} key={column.id}>
                            {column.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {rowsToDisplay.map((row) => (
                      <TableRow key={row.id} hover onClick={() => onRowClick(row)} style={{ cursor: 'pointer' }}>
                        <TableCell sx={{ width: checkboxColumnWidth }}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankRounded fontSize="small" />}
                            checkedIcon={<CheckBoxRounded fontSize="small" />}
                            id={row.id}
                            color="primary"
                            checked={selectedRowsIds.includes(row.id)}
                          />
                        </TableCell>

                        {columns.map((column) => (
                          <TableCell sx={{ width: columnWidth }} key={`${column.id}#${row.id}`}>
                            <DataSetCell column={column} value={row[column.id]} />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {!loadedAll && (
                  <Grid container item justifyContent={'center'} alignItems={'center'} sx={{ p: 2 }}>
                    <CircularProgress size={24} />
                  </Grid>
                )}
              </TableContainer>
            )}

            <Grid container sx={{ mt: 1 }}>
              {!isEmpty(selectedRowsIds) && (
                <Button
                  size="small"
                  onClick={() => setShowSelected(!showSelected)}
                  startIcon={showSelected ? <ListRounded /> : <ChecklistRounded />}
                >
                  show {showSelected ? 'all' : 'selected'}
                </Button>
              )}
              <Typography sx={{ fontSize: 14, ml: 'auto' }}>Total: {total}</Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Popover>
  );
}

function DataSetAutocomplete({ setId, columns, onRowClick, selectedRowsIds, selectType }) {
  const [searchRows, setSearchRows] = useState([]);
  const [loadingSearchRows, setLoadingSearchRows] = useState(false);
  const [totalSearch, setTotalSearch] = useState(0);
  const [open, setOpen] = useState(false);

  const [rows, setRows] = useState([]);
  const [totalCount, setTotaCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pattern, setPattern] = useState('');

  const searchMode = !isEmpty(String(pattern).trim());
  const rowsToDisplay = searchMode ? searchRows : rows;
  const total = searchMode ? totalSearch : totalCount;

  const loadedAll = searchMode ? searchRows.length >= totalSearch : rows.length >= totalCount;
  const loadFirstTime = open && isEmpty(rows);

  useEffect(() => {
    if (!loadFirstTime) return;

    loadRows(0);
  }, [loadFirstTime]); // eslint-disable-line

  const timer = useRef(null);

  useEffect(() => {
    if (!pattern) {
      setSearchRows([]);
      setLoadingSearchRows(false);
      return;
    }

    setLoadingSearchRows(true);

    if (timer.current != null) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => loadSearchRows(0, pattern), 800);
  }, [pattern]); // eslint-disable-line

  function loadRows(skip) {
    if (loading) return;

    setLoading(true);

    const invitationId = localStorage.getItem('invitationId');
    const url = `${invitationId ? '/external' : ''}/data/getDataSetRows`;
    const body = {
      setId,
      invitationId,
      limit: PAGE_SIZE,
      skip,
      columnsIds: isArray(columns) ? columns.map((x) => x.id) : [],
    };

    axios
      .post(url, body)
      .then(({ data }) => {
        setTotaCount(data.totalCount);
        setRows([...rows, ...data.rows]);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function loadSearchRows(skip, pattern, append) {
    if (!pattern) {
      setTotalSearch(0);
      setSearchRows([]);

      return;
    }

    setLoadingSearchRows(true);

    const invitationId = localStorage.getItem('invitationId');
    const url = `${invitationId ? '/external' : ''}/data/getDataSetRows`;
    const body = {
      setId,
      invitationId,
      limit: PAGE_SIZE,
      skip,
      columnsIds: isArray(columns) ? columns.map((x) => x.id) : [],
      pattern,
    };

    axios
      .post(url, body)
      .then(({ data }) => {
        setTotalSearch(data.totalCount);
        setSearchRows(append ? [...searchRows, ...data.rows] : data.rows);

        console.log('data', data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoadingSearchRows(false);
      });
  }

  const cellWidth = (100 - 10) / columns.length;

  return (
    <Autocomplete
      options={rowsToDisplay}
      filterOptions={(x) => x}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      fullWidth
      noOptionsText={!pattern ? 'Type to search' : loading || loadingSearchRows ? 'Loading..' : 'Nothing found'}
      inputValue={pattern}
      loading={loading || loadingSearchRows}
      onInputChange={(event, newInputValue) => {
        setPattern(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      disableCloseOnSelect
      getOptionLabel={(option) => ''}
      openOnFocus
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Search"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingSearchRows || loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      onChange={(e, row) => {
        if (!row?.id) return;
      }}
      renderOption={(props, row) => (
        <li
          {...props}
          key={row.id}
          onClick={(e) => {
            e.stopPropagation();
            onRowClick(row);

            if (selectType === 'single') {
              setOpen(false);
            }
          }}
        >
          <Grid container item alignItems="center" wrap="nowrap">
            {columns.map((column) => (
              <Grid item container style={{ width: `${cellWidth}%` }} key={`${column.id}#${row.id}`}>
                <DataSetCell column={column} value={row[column.id]} />
              </Grid>
            ))}
            <Grid item container style={{ width: '10%' }}>
              {selectedRowsIds.includes(row.id) ? (
                <CheckCircleRounded fontSize="small" style={{ color: green[500], marginLeft: 'auto' }} />
              ) : (
                <CheckCircleOutlineRounded fontSize="small" style={{ color: grey[300], marginLeft: 'auto' }} />
              )}
            </Grid>
          </Grid>
        </li>
      )}
      ListboxProps={{
        onScroll: (event) => {
          if (loadedAll) return;
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            if (searchMode) {
              loadSearchRows(searchRows.length, pattern, true);
            } else {
              loadRows(rows.length);
            }
          }
        },
      }}
    />
  );
}

function DataSetTickboxes({ setId, columns, onRowClick, selectedRowsIds, selectType, displayType }) {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!setId) return;

    setLoading(true);

    const invitationId = localStorage.getItem('invitationId');
    const url = `${invitationId ? '/external' : ''}/data/getDataSetRows`;
    const body = {
      setId,
      invitationId,
      limit: PAGE_SIZE,
      skpi: 0,
      columnsIds: isArray(columns) ? columns.map((x) => x.id) : [],
    };

    axios
      .post(url, body)
      .then(({ data }) => {
        setTotal(data.totalCount);
        setRows(data.rows);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setId, columns]);

  // too many rows - display as autocomplete
  if (total > PAGE_SIZE) {
    return (
      <DataSetAutocomplete
        setId={setId}
        columns={columns}
        onRowClick={onRowClick}
        selectedRowsIds={selectedRowsIds}
        selectType={selectType}
      />
    );
  }

  // only allow 1 column
  const column = columns[0];
  const columnId = column?.id;

  if (!columnId) return null;

  if (displayType === 'buttons') {
    return (
      <Grid item container spacing={2}>
        {rows.map((row) => (
          <Grid item key={row.id}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: 3,
                textTransform: 'none',
                color: selectedRowsIds.includes(row.id)
                  ? (theme) => theme.palette.getContrastText(theme.palette.primary.main)
                  : '',
                background: selectedRowsIds.includes(row.id) ? (theme) => theme.palette.primary.main : '',
                '&:hover': {
                  background: selectedRowsIds.includes(row.id) ? (theme) => theme.palette.primary.dark : '',
                },
              }}
              onClick={() => onRowClick(row)}
            >
              <DataSetCell column={column} value={row[column.id]} />
            </Button>
          </Grid>
        ))}
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid container>
        <Typography gutterBottom color={'text.secondary'}>
          Select value{selectType === 'multiple' ? 's' : ''}
        </Typography>
      </Grid>
      <Grid container>
        {rows.map((row) => (
          <Grid container item key={row.id}>
            <FormControlLabel
              label={<DataSetCell column={column} value={row[column.id]} />}
              control={
                <Checkbox
                  color="primary"
                  id={row.id}
                  checked={selectedRowsIds.includes(row.id)}
                  onChange={() => onRowClick(row)}
                />
              }
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

function DataSetCell({ column, value }) {
  return column.type === 'users' ? (
    isArray(value) && !isEmpty(value) ? (
      <UserGroup max={8} ids={value} fullWidth avatarSize={24} clickable={false} />
    ) : null
  ) : (
    <TooltipTypography>{value}</TooltipTypography>
  );
}
