import React, { forwardRef } from 'react';
import { Dialog, Slide, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Draggable from 'react-draggable';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(1.5),
    overflow: 'hidden',
  },
}));

function PaperComponent({ titleId, ...props }) {
  return (
    <Draggable handle={`#${titleId}`} cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function RoundedDialog({ children, titleId, ...props }) {
  return (
    <StyledDialog
      PaperComponent={titleId ? PaperComponent : undefined}
      PaperProps={titleId ? { titleId } : undefined}
      aria-labelledby={titleId ? titleId : undefined}
      TransitionComponent={Transition}
      {...props}
    >
      {children}
    </StyledDialog>
  );
}
