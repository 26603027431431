import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, IconButton, CircularProgress } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { grey } from '@mui/material/colors';
import { Edit } from '@mui/icons-material';
import { ProcessIcon } from '../Icons';
import axios from 'axios';

export default function ProcessAutocomplete({ processId, processName, saveProcess, disabled }) {
  const [availableProcesses, setAvailableProcesses] = useState([]);
  const [processesOpen, setProcessesOpen] = useState(false);
  const loadProcesses = processesOpen && availableProcesses.length === 0;

  useEffect(() => {
    if (!loadProcesses) return;
    axios
      .get('/process/search/getSearchProcesses')
      .then((res) => {
        setAvailableProcesses(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [loadProcesses]);

  return (
    <>
      {!processId && (
        <Autocomplete
          options={availableProcesses}
          style={{ marginLeft: '1rem', flexGrow: 1 }}
          getOptionLabel={(option) => `${option.title}${option._id}`}
          openOnFocus
          renderOption={(props, option) => (
            <li {...props} key={option._id}>
              <Grid container alignItems="center" wrap="nowrap">
                <ProcessIcon />
                <Typography style={{ marginLeft: '1rem' }} noWrap>
                  {option.title}
                </Typography>
              </Grid>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              {...params}
              label="Select a process"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loadProcesses ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(e, value) => {
            saveProcess(value);
          }}
          open={processesOpen}
          onOpen={() => setProcessesOpen(true)}
          onClose={() => setProcessesOpen(false)}
          loading={loadProcesses}
        />
      )}
      {processId && (
        <>
          <Typography variant="h6" noWrap style={{ marginLeft: '1rem', flexGrow: 1 }}>
            {processName}
          </Typography>
          {!disabled && (
            <IconButton onClick={() => saveProcess({})} style={{ marginLeft: 32 }}>
              <Edit style={{ color: grey[500] }} />
            </IconButton>
          )}
        </>
      )}
    </>
  );
}
