import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Typography, Grid, Chip, Paper, Button, IconButton, Collapse, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FormControlLabel, Switch } from '@mui/material';
import { TooltipTypography, User, UserGroup } from '../../../../Global/Components';
import { DeleteOutlineRounded, ExpandMoreRounded, FlashOnRounded } from '@mui/icons-material';
import { ArchiveRounded, UnarchiveRounded } from '@mui/icons-material';
import { blue, green, red, amber, deepOrange, grey } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import { FormatDate } from '../../../../Global/Functions';
import { isArray, isEmpty } from 'lodash';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { styled } from '@mui/material/styles';
import { formatEntryVersion } from '../../pages/ongoingProcess';
import { useDataGridPagination } from '../../../../Global/hooks';
import UserInput from '../../../../Global/DataGrid/Filters/UserInput';
import OptionsInput from '../../../../Global/DataGrid/Filters/OptionsInput';
import DeleteDialog from './DeleteDialog';
import moment from 'moment';
import axios from 'axios';
import DataGridViews from '../../../../Global/DataGrid/Views';
import StateManager from '../../../../Global/StateManager';

const ActionsStatusColors = {
  closed: green[600],
  overdue: red[800],
  hasActions: blue[800],
};

const EntryStateColors = {
  working: blue[500],
  completed: green[500],
  held: amber[600],
  cancelled: red[600],
};

const StateOptions = Object.keys(EntryStateColors).map((x) => ({
  _id: x,
  title: x,
}));

const CellTypography = styled(TooltipTypography)(() => ({
  fontSize: 14,
}));

export default function EntriesTable({ process, state, title, lineId, withoutPaper, defaultCollapsed, fillHeight }) {
  const [expanded, setExpanded] = useState(!defaultCollapsed);
  const [columns, setColumns] = useState([]);
  const [selected, setSelected] = useState([]);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteParams, setDeleteParams] = useState(null);
  const [withArchived, setWithArchived] = useState(false);
  const [hasArchived, setHasArchived] = useState(false);
  const firstRender = useRef(true);

  const history = useHistory();
  const configClass = state && process ? `${state}-process-entries-${process._id}` : '';

  const tableConfig = useDataGridPagination(
    configClass,
    columns,
    '/process/entries/getProcessEntriesTable',
    'entries',
    'totalCount',
    'personal',
    {
      checkboxPosition: 'left',
      requestData: { state, processId: process?._id, lineId, withArchived },
      defaultPageSize: state === 'all' ? 50 : 10,
      rowMapFunction: mapRow,
      pinnedRight: ['state', 'actionsStatus', 'newWindow'],
    },
  );

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    tableConfig.reloadCurrentPage();
  }, [withArchived]); // eslint-disable-line

  const { archivedSelected, unarchivedSelected } = useMemo(() => {
    const rows = tableConfig.getRows();
    const selectedRows = rows.filter((x) => selected.includes(x._id));

    return {
      archivedSelected: selectedRows.some((x) => x.archivedAt),
      unarchivedSelected: selectedRows.some((x) => !x.archivedAt && (x.completedAt || x.heldAt || x.cancelledAt)),
    };
  }, [selected]); // eslint-disable-line

  const lastOpenEntry = process ? localStorage.getItem(`last-entry-${process._id}`) : null;

  function mapRow(row) {
    return {
      ...row,
      id: row._id,
      ...row.referenceFieldsData,
    };
  }

  useEffect(() => {
    const refColumns =
      isArray(process?.referenceItems) && !isEmpty(process.referenceItems)
        ? process.referenceItems.map((field) => ({
            headerName: field.title,
            field: field.id,
            width: 250,
            renderCell: ({ value }) => <CellTypography>{value}</CellTypography>,
          }))
        : [];

    const portalSpacesLinksColumns =
      isArray(process?.linkedPortalSpaces) && !isEmpty(process.linkedPortalSpaces)
        ? [
            {
              headerName: 'Linked portal spaces',
              field: 'portalSpaceLinks',
              sortable: false,
              width: 250,
              valueGetter: ({ row }) => row.linkedPortalSpacesString || '',
              filterOperators: [
                {
                  label: 'Includes',
                  value: 'includes',
                  InputComponent: OptionsInput,
                  InputComponentProps: { options: process.linkedPortalSpaces || [] },
                },
                {
                  label: 'Is empty',
                  value: 'isEmpty',
                },
                {
                  label: 'Is not empty',
                  value: 'isNotEmpty',
                },
              ],
            },
          ]
        : [];

    const portalSpaceColumns =
      isArray(process?.portalSpaces) && !isEmpty(process.portalSpaces)
        ? [
            {
              field: 'portalSpaceId',
              width: 200,
              headerName: 'Portal space',
              valueGetter: ({ row }) => row.portalSpaceTitle || '',
              filterOperators: [
                {
                  label: 'Includes',
                  value: 'includes',
                  InputComponent: OptionsInput,
                  InputComponentProps: { options: process.portalSpaces || [] },
                },
                {
                  label: 'Is empty',
                  value: 'isEmpty',
                },
                {
                  label: 'Is not empty',
                  value: 'isNotEmpty',
                },
              ],
            },
          ]
        : [];

    const currStepColumns =
      !process?.stepsView || process?.stepsView === 'step'
        ? []
        : [
            {
              field: 'currStepTitle',
              width: 200,
              headerName: 'Current step',
              renderCell: ({ value, row }) => (row.completedAt ? '-' : <CellTypography>{value}</CellTypography>),
            },
          ];

    const hasPrefix = Boolean(process?.prefix?.text);
    const addPrefixToEntryNumber = hasPrefix && process.addPrefixToEntryNumber;

    const usersOnlyOperators = [
      {
        label: 'Includes',
        value: 'includes',
        InputComponent: UserInput,
        InputComponentProps: { type: 'user' },
        getValueAsString: (value) => (isArray(value) ? value.join(', ') : ''),
      },
    ];

    const actionsColumn = [
      {
        width: 70,
        headerName: 'Actions',
        field: 'actionsStatus',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <FlashOnRounded style={{ color: row.actionsStatus ? ActionsStatusColors[row.actionsStatus] : grey[500] }} />
        ),
      },
      {
        width: 70,
        field: 'newWindow',
        headerName: 'New tab',
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => (
          <Tooltip title="Open in new Tab">
            <IconButton onClick={(event) => handleNewTabClick(row, event)} margin={'auto'}>
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        ),
      },
    ];

    const standardColumns = [
      {
        field: 'number',
        headerName: '#',
        type: 'number',
        width: 66,
        renderCell: ({ row }) => (
          <CellTypography>
            {addPrefixToEntryNumber ? `${process.prefix.text} - ${row.number}` : row.number}
            {row.entryVersion ? `-${formatEntryVersion(process?.selectedVersionFormat, row.entryVersion)}` : ''}
          </CellTypography>
        ),
      },
      {
        headerName: 'Version',
        width: 80,
        type: 'number',
        field: 'version',
        renderCell: ({ row }) => (
          <Grid container alignItems="center">
            {row.testModeId && (
              <Chip
                label={'Test'}
                size="small"
                sx={{
                  background: deepOrange[700],
                  color: 'white',
                  mr: 1,
                }}
              />
            )}
            <CellTypography>{`v${row.version}${row.testVersion ? '.' : ''}${row.testVersion || ''}`}</CellTypography>
          </Grid>
        ),
      },
      {
        field: 'createdAt',
        width: 150,
        type: 'date',
        headerName: 'Started',
        valueGetter: (params) => (params.row.createdAt ? moment(params.row.createdAt).toDate() : null),
        renderCell: ({ value }) => <CellTypography>{FormatDate(value)}</CellTypography>,
      },
      {
        width: 250,
        headerName: 'Started by',
        field: 'createdBy',
        renderCell: ({ row }) => <User avatarSize={32} id={row.createdBy} />,
        valueGetter: ({ row }) => '',
        sortable: false,
        filterOperators: usersOnlyOperators,
      },
      ...portalSpacesLinksColumns,
      ...portalSpaceColumns,
    ];

    if (state === 'working') {
      const standardInProgressColumns = [
        ...standardColumns,
        {
          width: 250,
          headerName: 'Currently with',
          field: 'currStepUsers',
          sortable: false,
          renderCell: ({ row }) => (isEmpty(row.currStepUsers) ? null : <UserGroup ids={row.currStepUsers} />),
          valueGetter: ({ row }) => '',
          filterOperators: usersOnlyOperators,
        },
        ...currStepColumns,
        {
          field: 'currStepDue',
          width: 150,
          type: 'date',
          headerName: 'Due',
          valueGetter: (params) => (params.row.currStepDue ? moment(params.row.currStepDue).toDate() : null),
          renderCell: ({ value }) => <CellTypography>{value ? FormatDate(value) : '-'}</CellTypography>,
        },
        ...refColumns,
        ...actionsColumn,
      ];

      setColumns(standardInProgressColumns);
    }

    if (state === 'completed') {
      const standardCompletedColumns = [
        ...standardColumns,
        {
          field: 'completedAt',
          width: 150,
          type: 'date',
          headerName: 'Completed',
          valueFormatter: (params) => FormatDate(params.value),
          renderCell: ({ row }) => (
            <Grid container>
              {row.archivedAt && <Chip label={'Archived'} size="small" />}
              <CellTypography>{FormatDate(row.completedAt)}</CellTypography>
            </Grid>
          ),
        },
        ...refColumns,
        ...actionsColumn,
      ];

      setColumns(standardCompletedColumns);
    }

    if (state === 'held') {
      const standardHeldColumns = [
        ...standardColumns,
        {
          field: 'heldAt',
          width: 150,
          type: 'date',
          headerName: 'Held',
          valueFormatter: (params) => FormatDate(params.value),
        },
        ...refColumns,
        ...actionsColumn,
      ];

      setColumns(standardHeldColumns);
    }

    if (state === 'cancelled') {
      const standardCancelledColumns = [
        ...standardColumns,
        {
          field: 'cancelledAt',
          width: 150,
          type: 'date',
          headerName: 'Cancelled',
          valueFormatter: (params) => FormatDate(params.value),
        },
        ...refColumns,
        ...actionsColumn,
      ];

      setColumns(standardCancelledColumns);
    }

    if (state === 'all') {
      const standardInProgressColumns = [
        ...standardColumns,
        {
          width: 250,
          headerName: 'Currently with',
          field: 'currStepUsers',
          sortable: false,
          renderCell: ({ row }) =>
            row.completedAt ? '' : isEmpty(row.currStepUsers) ? null : <UserGroup ids={row.currStepUsers} />,
          valueGetter: ({ row }) => '',
          filterOperators: usersOnlyOperators,
        },
        ...currStepColumns,
        {
          field: 'currStepDue',
          width: 150,
          type: 'date',
          headerName: 'Due',
          valueGetter: (params) =>
            params.row.completedAt ? '' : params.row.currStepDue ? moment(params.row.currStepDue).toDate() : null,
          renderCell: ({ value }) => <CellTypography>{value ? FormatDate(value) : '-'}</CellTypography>,
        },
        {
          field: 'completedAt',
          width: 150,
          type: 'date',
          headerName: 'Completed',
          valueFormatter: (params) => (params.value ? FormatDate(params.value) : '-'),
        },
        ...refColumns,
        {
          width: 100,
          headerName: 'Status',
          field: 'state',
          renderCell: ({ value, row }) => (
            <Chip
              label={row.archivedAt ? 'Archived' : value}
              size="small"
              sx={{ background: row.archivedAt ? '' : EntryStateColors[value], color: 'white' }}
            />
          ),
          filterOperators: [
            {
              label: 'Includes',
              value: 'includes',
              InputComponent: OptionsInput,
              InputComponentProps: { options: StateOptions },
            },
          ],
        },
        ...actionsColumn,
      ];

      setColumns(standardInProgressColumns);
    }
    //eslint-disable-next-line
  }, [process, state]);

  function goToEntry(entryId, event) {
    const target = event.target.closest('button');
    if (target) return;
    localStorage.setItem(`last-entry-${process._id}`, entryId);
    history.push(`/processes/ongoing/${entryId}`);
  }

  function handleNewTabClick(params, event) {
    const entryId = params._id;
    localStorage.setItem(`last-entry-${process._id}`, entryId);
    window.open(`/processes/ongoing/${entryId}`, '_blank');
    event.preventDefault();
  }

  function deleteEntries() {
    tableConfig.reloadCurrentPage();
  }

  function confirmDeleteEntries(selected) {
    setDeleteDialog(true);
    setDeleteParams({ entries: selected });
  }

  function unarchiveEntries() {
    axios
      .post('/process/archive/unarchiveEntries', { entriesIds: selected })
      .then(({ data }) => {
        setSelected(selected.filter((x) => !data.unarchived?.includes(x)));
        tableConfig.reloadCurrentPage();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  function archiveEntries() {
    axios
      .post('/process/archive/archiveEntries', { entriesIds: selected })
      .then(({ data }) => {
        setSelected(selected.filter((x) => !data.archived?.includes(x)));
        setHasArchived(true);
        tableConfig.reloadCurrentPage();
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      });
  }

  const titles = {
    working: 'In progress',
    held: 'Held',
    cancelled: 'Cancelled',
    completed: 'Completed',
    all: 'Entries',
  };

  const colors = {
    working: blue[600],
    held: amber[600],
    cancelled: red[600],
    completed: green[600],
  };

  const containerProps = withoutPaper
    ? null
    : {
        component: Paper,
        variant: 'outlined',
        sx: { borderRadius: 1.5, mb: fillHeight ? 0 : 2, p: fillHeight ? 2 : 1 },
      };

  const Table = (
    <DataGridPremium
      disableAggregation
      disableRowGrouping
      autoHeight={false}
      disableRowSelectionOnClick
      onRowClick={({ row }, event) => goToEntry(row._id, event)}
      getRowClassName={({ row }) =>
        row.actionsStatus === 'overdue' ? 'overdue-row' : row.id === lastOpenEntry ? 'last-open-row' : undefined
      }
      checkboxSelection
      rowSelectionModel={selected}
      onRowSelectionModelChange={setSelected}
      slotProps={{
        filterPanel: {
          logicOperators: ['and'],
        },
      }}
      {...tableConfig.gridProps}
      sx={{
        minHeight: '50vh', // Set a consistent height for the table
        maxHeight: '75vh', // Allow for some flexibility but keep the table height within bounds
      }}
    />
  );

  return (
    <Grid
      container
      item
      style={{ height: fillHeight ? '100%' : undefined }}
      {...containerProps}
      direction={'column'}
      wrap="nowrap"
    >
      <Grid container alignItems="center" item sx={{ py: 1, px: 2 }}>
        <Typography variant="h5" style={{ color: colors[state] }}>
          {title || titles[state]}
        </Typography>
        <Chip
          label={tableConfig.gridProps.rowCount}
          sx={{ background: colors[state], color: (t) => t.palette.getContrastText(colors[state] || grey[300]), ml: 4 }}
        />
        <Grid sx={{ ml: 'auto' }} />
        {['completed', 'all'].includes(state) && (
          <>
            {(process?.hasArchived || hasArchived) && (
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={withArchived}
                    onChange={(e) => {
                      setWithArchived(e.target.checked);
                    }}
                  />
                }
                label="Show archived entries"
              />
            )}
            {archivedSelected && (
              <Button onClick={() => unarchiveEntries()} startIcon={<UnarchiveRounded />}>
                unarchive
              </Button>
            )}
            {unarchivedSelected && (
              <Button onClick={() => archiveEntries()} startIcon={<ArchiveRounded />}>
                archive
              </Button>
            )}
          </>
        )}
        {!isEmpty(selected) && (
          <Button
            startIcon={<DeleteOutlineRounded />}
            sx={{ color: red[500], mr: 1 }}
            onClick={() => confirmDeleteEntries(selected, 'working')}
          >
            delete {selected.length === 1 ? 'entry' : `${selected.length} entries`}
          </Button>
        )}

        <DeleteDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          entries={deleteParams?.entries}
          onResult={deleteEntries}
        />
        <DataGridViews
          initial={tableConfig.gridProps}
          id={tableConfig.configId}
          defaultId={tableConfig.defaultView}
          onResult={(data) => {
            tableConfig.onConfigurationChange(data);
          }}
          initialViews={tableConfig.views}
          size="medium"
        />
        {tableConfig.gridProps.rowCount > 0 && !fillHeight && (
          <IconButton
            onClick={() => setExpanded(!expanded)}
            sx={{
              transform: 'rotate(0deg)',
              transition: (theme) =>
                theme.transitions.create('transform', {
                  duration: theme.transitions.duration.shortest,
                }),
              transform: expanded ? 'rotate(180deg)' : null,
              ml: 2,
            }}
          >
            <ExpandMoreRounded />
          </IconButton>
        )}
      </Grid>
      {fillHeight ? (
        <Grid
          container
          item
          sx={{
            height: '100%',
            position: 'relative',
            '& .overdue-row': {
              backgroundColor: (theme) => (theme.palette.mode === 'dark' ? red[600] : red[100]),
              '&:hover': {
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? red[700] : red[200]),
              },
            },
            p: 1,
            minHeight: '25vh', // Minimum height to maintain consistency
            maxHeight: '75vh', // Maximum height for the grid
          }}
        >
          {Table}
        </Grid>
      ) : (
        <Grid container component={Collapse} in={expanded}>
          <Grid
            container
            item
            sx={{
              height: '100%',
              position: 'relative',
              '& .overdue-row': {
                backgroundColor: (theme) => (theme.palette.mode === 'dark' ? red[600] : red[100]),
                '&:hover': {
                  backgroundColor: (theme) => (theme.palette.mode === 'dark' ? red[700] : red[200]),
                },
              },
              p: 1,
              minHeight: '25vh',
              maxHeight: '75vh',
            }}
          >
            {Table}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
