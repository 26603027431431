import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { InputBase, IconButton, Grid, Paper, Typography, Divider, List, ListSubheader } from '@mui/material';
import { CircularProgress } from '@mui/material';

import { CloseRounded, SearchRounded } from '@mui/icons-material';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import FileIconAvatar from '../../Hubs/dochub/components/FileIconAvatar';
import HubItem from './item';
import { TaskIcon, ProjectIcon, RoutineIcon, TeamIcon, FormIcon, ProcessIcon, AssetIcon } from '../Icons';
import { KPIHubIcon, AuditIcon } from '../Icons';
import { DashboardRounded } from '@mui/icons-material';
import { grey, teal } from '@mui/material/colors';
import StateManager from '../StateManager';

import { styled, alpha } from '@mui/material/styles';
import axios from 'axios';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => {
  return {
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
    },
    width: '100%',
  };
});

const SearchResultsPaper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  zIndex: 999,
  right: 0,
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(1),
  width: '140%',
  left: '-20%',
}));

const SearchResultsContainer = styled(Grid)(({ theme }) => ({
  maxHeight: '50vh',
  overflow: 'auto',
}));

const itemTypes = {
  task: { icon: <TaskIcon />, text: 'Task' },
  routine: { icon: <RoutineIcon />, text: 'Routine' },
  taskTemplate: { icon: <TaskIcon />, text: 'Task template' },
  project: { icon: <ProjectIcon />, text: 'Project' },
  team: { icon: <TeamIcon />, text: 'Team' },
  form: { icon: <FormIcon />, text: 'Form' },
  process: { icon: <ProcessIcon />, text: 'Process' },
  asset: { icon: <AssetIcon />, text: 'Asset' },
  kpiDashboard: { icon: <DashboardRounded style={{ color: teal[500] }} />, text: 'KPI Dashboard' },
  kpi: { icon: <KPIHubIcon />, text: 'KPI' },
  auditTemplate: { icon: <AuditIcon />, text: 'Audit' },
};

export default ({ empty }) => {
  const SEARCH_TIMEOUT = 1200;
  const history = useHistory();
  const [pattern, setPattern] = useState('');

  const [loading, setLoading] = useState(null);
  const [loadingDL, setLoadingDL] = useState(null);
  const [results, setResults] = useState(null);
  const [resultsDL, setResultsDL] = useState(null);
  const [error, setError] = useState(false);
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const timer = useRef(null);

  const controller = useRef(new AbortController());

  function search(query) {
    controller.current = new AbortController();
    setLoading(true);
    setLoadingDL(true);
    axios
      .get('/search', { params: { q: query }, signal: controller.current.signal })
      .then(({ data }) => {
        setResults(data);
        setLoading(false);
        setError(false);
      })
      .catch((err) => {
        if (err.message === 'canceled') return;
        StateManager.setAxiosErrorAlert(err);
        setLoading(false);
        setError(true);
      });

    axios
      .get('/search/searchDocHub', { params: { pattern: query }, signal: controller.current.signal })
      .then(({ data }) => {
        setResultsDL(searchResultsOpen ? data : null);
        setLoadingDL(false);
        setError(false);
      })
      .catch((err) => {
        if (err.message === 'canceled') return;
        StateManager.setAxiosErrorAlert(err);
        setLoadingDL(false);
        setError(true);
      });
  }

  function activateTimer(query) {
    if (timer.current != null) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(search, SEARCH_TIMEOUT, query);
  }

  useEffect(() => {
    document.addEventListener('click', detectBackgroundClick);
    return () => {
      document.removeEventListener('click', detectBackgroundClick);
    };
  }, []);

  function detectBackgroundClick() {
    setSearchResultsOpen(false);
  }

  const openFileLocation = (selected) => {
    setPattern('');
    setResults(null);
    setResultsDL(null);
    history.push(`/DocLibrary/preview/${selected._id}`);
  };

  const openFolder = (selected) => {
    setPattern('');
    setResults(null);
    setResultsDL(null);
    history.push(`/DocLibrary/areas/${selected.area}/${selected._id}`);
  };

  const openArea = (selected) => {
    setPattern('');
    setResults(null);
    history.push(`/DocLibrary/areas/${selected._id}`);
  };

  const onItemClick = (selected) => {
    setPattern('');
    setResults(null);
    setResultsDL(null);
    setLoading(null);
    setLoadingDL(null);
    controller.current.abort();
    if (selected.type === 'task') {
      StateManager.selectTask(selected._id);
      return;
    }
    if (selected.type === 'routine') {
      history.push(`/tasks/routine/${selected._id}`);
      return;
    }
    if (selected.type === 'taskTemplate') {
      history.push(`/tasks/templates?selected=${selected._id}`);
      return;
    }
    if (selected.type === 'project') {
      history.push(`/tasks/projects/project/${selected._id}`);
      return;
    }
    if (selected.type === 'team') {
      history.push(`/tasks/team/${selected._id}`);
      return;
    }
    if (selected.type === 'form') {
      history.push(`/forms/${selected._id}`);
      return;
    }
    if (selected.type === 'process') {
      history.push(`/processes/${selected._id}`);
      return;
    }
    if (selected.type === 'asset') {
      history.push(`/asset/${selected._id}`);
      return;
    }
    if (selected.type === 'kpiDashboard') {
      history.push(`/kpiHub/dashboard/${selected._id}`);
      return;
    }
    if (selected.type === 'kpi') {
      history.push(`/kpiHub/${selected.accessType === 'public' ? 'company-kpi' : 'myKpi'}?selected=${selected._id}`);
      return;
    }
    if (selected.type === 'auditTemplate') {
      history.push(`/audit/template/${selected._id}`);
      return;
    }
  };

  const noDlResults = !resultsDL || Object.values(resultsDL).findIndex((x) => x.length) === -1;
  const noMyTaskResults = !Array.isArray(results?.mytasks) || results.mytasks.length === 0;
  const noFormResults = !Array.isArray(results?.formhub) || results.formhub.length === 0;
  const noProcResults = !Array.isArray(results?.processhub) || results.processhub.length === 0;
  const noAssetResults = !Array.isArray(results?.assethub) || results.assethub.length === 0;
  const noKPIResults = !Array.isArray(results?.kpihub) || results.kpihub.length === 0;
  const noAuditResults = !Array.isArray(results?.audithub) || results.audithub.length === 0;

  const noResults =
    noMyTaskResults &&
    noDlResults &&
    noFormResults &&
    noProcResults &&
    noAssetResults &&
    noKPIResults &&
    noAuditResults;
  return (
    <Grid container>
      <Search>
        <SearchIconWrapper>
          <SearchRounded />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
          value={pattern}
          onChange={(e) => {
            if (empty) return;
            setPattern(e.target.value);
            activateTimer(e.target.value);
            setSearchResultsOpen(true);
          }}
          onFocus={(e) => setSearchResultsOpen(true)}
          onClick={(e) => e.stopPropagation()}
          endAdornment={
            pattern && (
              <IconButton
                size="small"
                onClick={() => {
                  setPattern('');
                  setResults(null);
                  setResultsDL(null);
                  setLoading(null);
                }}
                style={{ color: 'white', marginRight: 8 }}
              >
                {loading || loadingDL ? <CircularProgress size={24} style={{ color: 'white' }} /> : <CloseRounded />}
              </IconButton>
            )
          }
        />
        {searchResultsOpen && !empty && (!noResults || loading === false) && pattern && (
          <SearchResultsPaper onClick={(e) => e.stopPropagation()} elevation={12}>
            <SearchResultsContainer container className="scroll-bar">
              {noResults && !loading && !loadingDL && !error && (
                <Typography variant="h6" style={{ margin: 8 }}>
                  <span style={{ color: grey[500] }}>Nothing was found</span>{' '}
                  <span style={{ fontSize: '2em' }}>😢</span>
                </Typography>
              )}
              {error && !loading && (
                <Typography variant="h6" style={{ margin: 8 }}>
                  <span style={{ color: grey[500] }}>Oops... Something went wrong</span>
                </Typography>
              )}
              {!noMyTaskResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">My tasks</ListSubheader>}>
                    {results.mytasks.map((item) => (
                      <HubItem
                        key={item._id}
                        onAction={() => onItemClick(item)}
                        title={item.title}
                        entry={itemTypes[item.type]?.text}
                        icon={itemTypes[item.type]?.icon}
                      />
                    ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
              {/* FROM DOC LIBRARY */}
              {!noDlResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">Dochub</ListSubheader>}>
                    {resultsDL?.documents?.length > 0 &&
                      resultsDL.documents.map((r) => (
                        <HubItem
                          key={r._id}
                          onAction={() => openFileLocation(r)}
                          title={r.title}
                          entry={'Document'}
                          icon={<FileIconAvatar document={r} />}
                        />
                      ))}
                    {resultsDL?.taggedDocuments?.length > 0 &&
                      resultsDL.taggedDocuments.map((r) => (
                        <HubItem
                          key={r._id}
                          onAction={() => openFileLocation(r)}
                          title={r.title}
                          entry={'Tagged Document'}
                          icon={<FileIconAvatar document={r} />}
                          tags={r.tags}
                        />
                      ))}
                    {resultsDL?.areas?.length > 0 &&
                      resultsDL.areas.map((r) => (
                        <HubItem
                          key={r._id}
                          onAction={() => openArea(r)}
                          title={r.name}
                          entry={'Area'}
                          icon={<FolderSpecialIcon />}
                          tags={
                            r.prefix
                              ? [
                                  {
                                    ...r.prefix,
                                    name: r.prefix?.text,
                                    color: r.prefix?.color?.replace('#', ''),
                                  },
                                ]
                              : []
                          }
                        />
                      ))}
                    {resultsDL?.folders?.length > 0 &&
                      resultsDL.folders.map((r) => (
                        <HubItem
                          key={r._id}
                          onAction={() => openFolder(r)}
                          title={r.name}
                          entry={'Folder'}
                          icon={<FolderIcon />}
                        />
                      ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
              {/* FROM FORMS */}
              {!noFormResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">Forms</ListSubheader>}>
                    {results.formhub.map((item) => (
                      <HubItem
                        key={item._id}
                        onAction={() => onItemClick(item)}
                        title={item.title}
                        entry={itemTypes[item.type]?.text}
                        icon={itemTypes[item.type]?.icon}
                      />
                    ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
              {/* FROM PROCESSES */}
              {!noProcResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">Processes</ListSubheader>}>
                    {results.processhub.map((item) => (
                      <HubItem
                        key={item._id}
                        onAction={() => onItemClick(item)}
                        title={item.title}
                        entry={itemTypes[item.type]?.text}
                        icon={itemTypes[item.type]?.icon}
                      />
                    ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
              {/* FROM ASSETS */}
              {!noAssetResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">Assets</ListSubheader>}>
                    {results.assethub.map((item) => (
                      <HubItem
                        key={item._id}
                        onAction={() => onItemClick(item)}
                        title={item.title}
                        entry={itemTypes[item.type]?.text}
                        icon={itemTypes[item.type]?.icon}
                      />
                    ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
              {/* FROM KPIS */}
              {!noKPIResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">KPIs</ListSubheader>}>
                    {results.kpihub.map((item) => (
                      <HubItem
                        key={item._id}
                        onAction={() => onItemClick(item)}
                        title={item.title}
                        entry={itemTypes[item.type]?.text}
                        icon={itemTypes[item.type]?.icon}
                      />
                    ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
              {/* FROM AUDITS */}
              {!noAuditResults && (
                <>
                  <Grid container component={List} subheader={<ListSubheader color="primary">Audits</ListSubheader>}>
                    {results.audithub.map((item) => (
                      <HubItem
                        key={item._id}
                        onAction={() => onItemClick(item)}
                        title={item.title}
                        entry={itemTypes[item.type]?.text}
                        icon={itemTypes[item.type]?.icon}
                      />
                    ))}
                  </Grid>
                  <Divider variant={'fullWidth'} />
                </>
              )}
            </SearchResultsContainer>
          </SearchResultsPaper>
        )}
      </Search>
    </Grid>
  );
};
