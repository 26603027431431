import { useEffect, useState } from 'react';
import { RoundedDialog, StandardDialogActions, FormEntriesDialog } from '../../../Global/Components';
import { ProcessEntriesDialog, RegisterRowsDialog } from '../../../Global/Components';
import { DialogContent, Grid, Typography, Skeleton, Button } from '@mui/material';
import { FormIcon, ProcessIcon, RegistersIcon } from '../../../Global/Icons';
import Chart from '../../../Global/Charts/Chart';
import StateManager from '../../../Global/StateManager';
import { Link } from 'react-router-dom';
import { blue, red, amber, grey } from '@mui/material/colors';
import { LabelRounded, EditRounded, DeleteOutlineRounded, CallSplitRounded } from '@mui/icons-material';
import { FiberManualRecord, PrintRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import KpiFilters from './KpiFilters';
import { isFunction, isNumber, isEmpty, toNumber } from 'lodash';
import { BASE_URL } from '../../../../constants';

export default function GenericKpiDialog({ open, onClose, onDelete, kpi, onChange, pageType }) {
  const [info, setInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [data, setData] = useState(null);
  const [loadingChart, setLoadingChart] = useState(false);
  const [saving, setSaving] = useState(false);
  const [filters, setFilters] = useState(null);

  const [formEntriesDialog, setFormEntriesDialog] = useState(false);
  const [formEntriesParams, setFormEntriesParams] = useState(null);

  const [processEntriesDialog, setProcessEntriesDialog] = useState(false);
  const [processEntriesParams, setProcessEntriesParams] = useState(null);

  const [registerEntriesDialog, setRegisterEntriesDialog] = useState(false);
  const [registerEntriesParams, setRegisterEntriesParams] = useState(null);

  const [loadingEntriesInfo, setLoadingEntriesInfo] = useState(false);

  const { user } = useSelector(({ profile }) => profile);
  const userId = user?._id;
  const admin = user?.access === 'admin';
  const editable = userId && (userId === kpi?.ownerId || admin);
  const filtersLocked = kpi?.lockFilters && !editable;

  useEffect(() => {
    if (!kpi) {
      setData(null);
      return;
    }

    setData(kpi.data);
    setFilters(kpi.filters);

    if (!open) return;

    setLoadingInfo(true);

    axios
      .get('kpi/general/getKpiDataSourceInfo', { params: { kpiId: kpi._id } })
      .then(({ data }) => setInfo(data))
      .catch((err) => StateManager.setAxiosErrorAlert(err))
      .finally(() => setLoadingInfo(false));
  }, [kpi, open]);

  function confirmDelete() {
    StateManager.setConfirm('You are about to delete this KPI', deleteKpi);
  }

  function deleteKpi() {
    setSaving(true);
    axios
      .post('/kpi/general/deleteKpi', { kpiId: kpi._id })
      .then((res) => {
        onClose();
        isFunction(onDelete) && onDelete();
        StateManager.setSuccessAlert('KPI has been deleted');
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  function applyFilters(filters) {
    setFilters(filters);

    setLoadingChart(true);

    axios
      .post('/kpi/general/applyFilters', { kpiId: kpi._id, filters })
      .then(({ data }) => {
        setData(data.data);
        isFunction(onChange) && onChange({ _id: kpi._id, data: data.data, filters });
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoadingChart(false);
      });
  }

  function getEntries(params) {
    setLoadingEntriesInfo(true);

    const type = kpi.dataSource.type;

    if (type === 'form') {
      setFormEntriesDialog(true);
    }

    if (type === 'process') {
      setProcessEntriesDialog(true);
    }

    if (type === 'register') {
      setRegisterEntriesDialog(true);
    }

    axios
      .post('/kpi/general/getChartEntries', { kpiId: kpi._id, params })
      .then(({ data }) => {
        if (!data) {
          setFormEntriesDialog(false);
          setProcessEntriesDialog(false);
          setRegisterEntriesDialog(false);
          return;
        }
        if (type === 'form') {
          setFormEntriesParams(data);
        }

        if (type === 'process') {
          setProcessEntriesParams(data);
        }

        if (type === 'register') {
          setRegisterEntriesParams(data);
        }
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoadingEntriesInfo(false);
      });
  }

  function onBarClick(seriesIndexStr, dataIndex) {
    if (!seriesIndexStr || !isNumber(dataIndex) || isEmpty(kpi.data?.series)) {
      return;
    }

    const seriesIndex = toNumber(seriesIndexStr);

    getEntries({ seriesIndex, dataIndex, type: 'bar' });
  }

  function onMarkClick(dataIndex) {
    if (!isNumber(dataIndex) || isEmpty(kpi.data?.data)) {
      return;
    }

    getEntries({ dataIndex, type: 'line' });
  }

  function onPieClick(pieData) {
    getEntries({ pieData, type: 'pie' });
  }

  function print() {
    setSaving(true);
    const element = document.getElementById('chart');
    if (!element) {
      setSaving(false);
      return;
    }

    import('html2canvas').then(({ default: html2canvas }) => {
      html2canvas(element).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const body = {
          image,
          title: kpi.title,
          formInfo: info?.dataSourceType === 'form' ? info : null,
          processInfo: info?.dataSourceType === 'process' ? info : null,
          registerInfo: info?.dataSourceType === 'register' ? info : null,
        };
        axios
          .post('/kpi/exportToPdf', body)
          .then((res) => {
            window.open(`${BASE_URL}${res.data.link}`, '_blank');
            setSaving(false);
          })
          .catch((err) => {
            setSaving(false);
            StateManager.setAxiosErrorAlert(err);
          });
      });
    });
  }

  return (
    <RoundedDialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogContent>
        <Grid container item>
          <Grid container item sx={{ mb: 1 }} alignContent="flex-start">
            <Grid container item xs={6} alignContent="flex-start">
              <Typography variant="h5">{kpi?.title}</Typography>
            </Grid>
            <Grid container item xs={6} alignContent="flex-start">
              {!filtersLocked && (
                <KpiFilters
                  initial={filters}
                  onChange={(value) => applyFilters({ ...filters, ...value })}
                  portalSpaces={info?.portalSpaces}
                  dataSource={kpi?.dataSource}
                  kpiId={kpi?._id}
                  pageType={pageType}
                  kpiTitle={kpi?.title}
                />
              )}
            </Grid>
          </Grid>
          {loadingInfo ? (
            <Grid container item direction={'column'}>
              <Skeleton width="50%" sx={{ my: 1 }} />
              <Skeleton width="30%" sx={{ my: 1 }} />
            </Grid>
          ) : (
            <>
              {info?.dataSourceType === 'form' && (
                <Grid container item xs={6} alignContent="flex-start">
                  <Grid container item alignItems="center" sx={{ mb: 1 }}>
                    <FormIcon />
                    <Typography variant="subtitle1" sx={{ ml: 2 }}>
                      {info?.dataType === 'entries' ? 'Entries for form: ' : 'Form: '}
                      <Link to={info.outdated ? `/forms/version-info/${info.formVersion}` : `/forms/${info.formId}`}>
                        <span style={{ color: blue[700], fontWeight: 500 }}>{info.formTitle}</span>
                      </Link>
                    </Typography>
                  </Grid>

                  {info.fieldTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Field: <span style={{ fontWeight: 500 }}>{info.fieldTitle}</span>
                      </Typography>
                    </Grid>
                  )}

                  {info.baseFieldTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Base field: <span style={{ fontWeight: 500 }}>{info.baseFieldTitle}</span>
                      </Typography>
                    </Grid>
                  )}

                  {info.outdated && (
                    <Grid container item>
                      <Typography gutterBottom style={{ fontWeight: 500, color: red[700] }}>
                        This field does not exist in the latest version of the form
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
              {info?.dataSourceType === 'process' && (
                <Grid container item xs={6} alignContent="flex-start">
                  <Grid container item alignItems="center" sx={{ mb: 1 }}>
                    <ProcessIcon />
                    <Typography variant="subtitle1" sx={{ ml: 2 }}>
                      Process:{' '}
                      <Link
                        to={
                          info.outdated
                            ? `/processes/version-info/${info.processVersion}`
                            : `/processes/${info.processId}`
                        }
                      >
                        <span style={{ color: blue[700], fontWeight: 500 }}>{info.processTitle}</span>
                      </Link>
                    </Typography>
                  </Grid>

                  {info.stepTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <FiberManualRecord color="primary" />
                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Step: <span style={{ fontWeight: 500 }}>{info.stepTitle}</span>
                      </Typography>
                    </Grid>
                  )}

                  {info.isDecision && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <CallSplitRounded style={{ color: grey[600] }} />
                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Decision
                      </Typography>
                    </Grid>
                  )}

                  {info.fieldTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Field: <span style={{ fontWeight: 500 }}>{info.fieldTitle}</span>
                      </Typography>
                    </Grid>
                  )}

                  {info.baseFieldTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Base field: <span style={{ fontWeight: 500 }}>{info.baseFieldTitle}</span>
                      </Typography>
                    </Grid>
                  )}

                  {info.outdated && (
                    <Grid container item>
                      <Typography gutterBottom style={{ fontWeight: 500, color: red[700] }}>
                        This field does not exist in the latest version of the process
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}

              {info?.dataSourceType === 'register' && (
                <Grid container item xs={6} alignContent="flex-start">
                  <Grid container item alignItems="center" sx={{ mb: 1 }}>
                    <RegistersIcon />
                    <Typography variant="subtitle1" sx={{ ml: 2 }}>
                      Register:{' '}
                      <Link to={`/registers/${info.registerId}`}>
                        <span style={{ color: blue[700], fontWeight: 500 }}>{info.registerTitle}</span>
                      </Link>
                    </Typography>
                  </Grid>

                  {info.columnTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Column: <span style={{ fontWeight: 500 }}>{info.columnTitle}</span>
                      </Typography>
                    </Grid>
                  )}

                  {info.baseColumnTitle && (
                    <Grid container item alignItems="center" sx={{ mb: 1 }}>
                      <LabelRounded style={{ color: amber[500] }} />

                      <Typography variant="subtitle1" sx={{ ml: 2 }}>
                        Base column: <span style={{ fontWeight: 500 }}>{info.baseColumnTitle}</span>
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </>
          )}
          <Grid container item alignItems="center" justifyContent="center" id="chart" sx={{ height: '55vh' }}>
            <Chart
              loading={loadingChart}
              data={data}
              chartType={kpi?.chartParams?.chartType}
              barChartType={kpi?.chartParams?.barChartType}
              onBarClick={onBarClick}
              onPieClick={onPieClick}
              onMarkClick={onMarkClick}
              chartParams={kpi?.chartParams}
            />

            <FormEntriesDialog
              open={formEntriesDialog}
              onClose={() => {
                setFormEntriesDialog(false);
                setFormEntriesParams(null);
              }}
              loadingInfo={loadingEntriesInfo}
              {...formEntriesParams}
            />

            <ProcessEntriesDialog
              open={processEntriesDialog}
              onClose={() => {
                setProcessEntriesDialog(false);
                setProcessEntriesParams(null);
              }}
              loadingInfo={loadingEntriesInfo}
              {...processEntriesParams}
            />

            <RegisterRowsDialog
              open={registerEntriesDialog}
              onClose={() => {
                setRegisterEntriesDialog(false);
                setRegisterEntriesParams(null);
              }}
              loadingInfo={loadingEntriesInfo}
              {...registerEntriesParams}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          kpi &&
          editable && (
            <>
              <Link to={`/kpiHub/generic-kpi/${kpi._id}`}>
                <Button style={{ color: grey[600] }} startIcon={<EditRounded />}>
                  Edit
                </Button>
              </Link>

              {isFunction(onDelete) && (
                <Button style={{ color: red[500] }} onClick={confirmDelete} startIcon={<DeleteOutlineRounded />}>
                  delete
                </Button>
              )}

              <Button onClick={print} style={{ color: grey[600] }} startIcon={<PrintRounded />}>
                print
              </Button>

              <Grid style={{ marginRight: 'auto' }} />
            </>
          )
        }
        saving={saving}
        onClose={onClose}
      />
    </RoundedDialog>
  );
}
