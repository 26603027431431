import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchComments } from '../../redux/actions/comment';
import { getLinkedFiles } from '../../redux/actions';
import { fetchApprovalsByActivity } from '../../../../../redux/actions';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import User from '../../../../Global/Components/User';
import { IconButton, Paper } from '@mui/material';

import CommentsSection from '../Comments';

import { formatDate, VFORMAT, formatMiniRevisionIteration } from '../../constants';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

import { TooltipTypography } from '../../../../Global/Components';
import StatusIcon from './status';
import LinkTable from './link';
import { getSignatureName } from '../../utils';
import ActionGroup from '../../../../Global/Fields/ActionGroup';

const IssueInfo = ({ open, onClose, document, baseIssue, miniVersion }) => {
  const dispatch = useDispatch();

  const { revComments, revFetching, linkedFiles } = useSelector(({ dochub }) => dochub);
  const { approvals } = useSelector(({ approval }) => approval);

  useEffect(() => {
    if (baseIssue !== null) {
      dispatch(fetchApprovalsByActivity(baseIssue));
      if (miniVersion !== null) {
        dispatch(fetchComments(baseIssue, { miniVersion }));
        dispatch(getLinkedFiles({ sourceIssue: baseIssue, status: 'published', miniVersion: { $lte: miniVersion } }));
      }
    }
  }, [dispatch, baseIssue, miniVersion]);

  const handleLink = (data) => {
    let url = `/${data.targetType}${data.targetType === 'process' ? 'es' : 's'}/${data.targetItem}`;
    if (data?.targetType === 'document') {
      url = `/DocLibrary/preview/${data.targetItem}`;
    }
    window.open(url, '_blank');
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'md'}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '32px',
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{
          padding: `30px 20px`,
        }}
      >
        <Grid
          item
          xs={12}
          sm={3}
          container
          direction="row"
          justifyContent="flex-end"
          sx={{ position: 'absolute', top: '10px', right: '1%' }}
        >
          <IconButton
            size="small"
            onClick={onClose}
            sx={{
              borderRadius: 0,
              '&.MuiIconButton-root:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} container direction="row">
          <Grid
            item
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography color="textSecondary" variant="subtitle2">
                Created at: {formatDate(document?.createdAt, 'DD/MM/YYYY')}
              </Typography>
              <Typography color="primary" variant="h6">
                version:{' '}
                {formatMiniRevisionIteration(
                  document.baseVersion,
                  document?.revisionFormat || VFORMAT,
                  document.currentIteration,
                  document?.manualIssueControl,
                  document?.manualIssue,
                )}
              </Typography>
            </Grid>
            <Grid container direction={'row'} alignItems={'center'} sx={{ margin: '5px 0px' }}>
              <TooltipTypography variant="h4">{document?.title}</TooltipTypography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <Grid
              container
              direction={'row'}
              justifyContent={'space-between'}
              sx={{ margin: '5px 0px' }}
              alignItems="center"
            >
              <Typography sx={{ fontWeight: 500 }}>Who started?</Typography>
              <User id={document.issuedBy} avatarSize={20} />
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }}>Reason for change?</Typography>
              <Typography>{document.revisionReason}</Typography>
            </Grid>
            <Divider sx={{ margin: '15px 0px' }} />
            <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }}>What changed?</Typography>
              <Typography>{document.revisionChanges}</Typography>
            </Grid>
            <Divider sx={{ margin: '15px 0px' }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container direction={'row'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                Links from this document
              </Typography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            {linkedFiles && linkedFiles.length === 0 && <Typography>No references available</Typography>}
            <Grid container>
              <LinkTable linkedFiles={linkedFiles} document={document} handleLink={handleLink} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container direction={'row'} sx={{ margin: '5px 0px' }}>
              <Typography sx={{ fontWeight: 500 }} variant="h6">
                Actions
              </Typography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <Grid container>
              <ActionGroup
                activityInfo={{ entryId: baseIssue, type: 'documentIssue' }}
                itemId={baseIssue}
                editable={false}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} container direction="row">
          <Grid
            item
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
            alignContent="flex-start"
          >
            <Grid container>
              <Typography variant={'h5'}> Reviews </Typography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            {document?.reviewers?.map((review) => (
              <React.Fragment key={review._id}>
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems="center"
                  sx={{ margin: '5px 0px' }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Who did the review?</Typography>
                  <User id={review.user} avatarSize={20} />
                </Grid>
                <Divider sx={{ margin: '5px 0px' }} />
                <Grid container direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontWeight: 500 }}>When was the review?</Typography>
                  <Typography>{formatDate(review.submittedAt, 'DD-MM-YYYY')}</Typography>
                </Grid>
                <Divider sx={{ margin: '5px 0px' }} />
                <Grid container direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontWeight: 500 }}>What was the status?</Typography>
                  {review.state === 'approved' && (
                    <Typography sx={{ textTransform: 'capitalize', color: (theme) => theme.palette.success.main }}>
                      {review.state}
                    </Typography>
                  )}
                  {(review.state === 'rejected' || review.state === 'cancelled') && (
                    <Typography sx={{ textTransform: 'capitalize', color: (theme) => theme.palette.error.main }}>
                      {review.state}
                    </Typography>
                  )}
                  {review.state === 'comments' && (
                    <Typography sx={{ textTransform: 'capitalize' }}>{review.state}</Typography>
                  )}
                </Grid>
                <Divider sx={{ margin: '15px 0px' }} />
                <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
                  <Typography sx={{ fontWeight: 500 }}>What was the review outcome?</Typography>
                  <Typography sx={{ margin: '5px 0px' }}>{review.outcome}</Typography>
                </Grid>
                <div sx={{ margin: '5px 0px' }} />
                {review?.signature && (
                  <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems="center"
                    sx={{ margin: '5px 0px' }}
                  >
                    <Typography sx={{ fontWeight: 500 }}>Signature</Typography>
                    <Paper variant="outlined" sx={{ p: 1, borderRadius: 1, display: 'flex', alignItems: 'center' }}>
                      <img
                        style={{
                          width: '100%',
                          height: 75,
                          objectFit: 'contain',
                          userSelect: 'none',
                          pointerEvents: 'none',
                          verticalAlign: 'middle',
                        }}
                        src={review?.signature?.base64Data}
                        alt="signature"
                      />
                    </Paper>
                  </Grid>
                )}
                {review?.signature && (
                  <Typography color="textSecondary" variant="subtitle2" sx={{ fontSize: 12 }} align="right">
                    {review?.signature && 'Signed '} {formatDate(review?.submittedAt, 'hh:mm a DD/MM/YYYY')}{' '}
                    {getSignatureName(review)}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Grid>
          {document?.stage === 'Approval' && (
            <Grid
              item
              sm={12}
              component={Paper}
              sx={{
                borderRadius: '22px',
                boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
                padding: '20px',
                marginRight: '20px',
                marginBottom: '20px',
              }}
              container
              direction="column"
            >
              <Grid container>
                <Typography variant={'h5'}> Approvals </Typography>
              </Grid>
              <Divider sx={{ margin: '5px 0px' }} />
              <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
                alignItems="center"
                sx={{ margin: '5px 0px' }}
              >
                <Typography sx={{ fontWeight: 500 }}>Who did the approval?</Typography>
                {approvals && approvals?.userApprovals && approvals?.userApprovals.length > 0 && (
                  <User id={approvals?.userApprovals[approvals.userApprovals.length - 1].userId} avatarSize={20} />
                )}
              </Grid>
              <Divider sx={{ margin: '5px 0px' }} />
              <Grid container direction={'row'} justifyContent={'space-between'}>
                <Typography sx={{ fontWeight: 500 }}>When was it submitted for approval?</Typography>
                {approvals && <Typography>{formatDate(approvals?.submittedAt, 'DD-MM-YYYY')}</Typography>}
              </Grid>
              <Divider sx={{ margin: '15px 0px' }} />
              <Grid container direction={'column'} justifyContent={'space-between'} sx={{ margin: '5px 0px' }}>
                <Typography sx={{ fontWeight: 500 }}>What were the comments?</Typography>
                {approvals?.userApprovals?.map((userApproval) =>
                  userApproval?.approvals?.map((data) => (
                    <Grid
                      item
                      container
                      key={data._id}
                      sx={{
                        position: 'relative',
                        marginTop: '25px',
                        margin: '25px 0px',
                      }}
                    >
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          position: 'absolute',
                          top: '-18px',
                        }}
                      >
                        <User
                          id={userApproval.userId}
                          avatarSize={40}
                          buttonStyle={{ padding: '0px' }}
                          listItemStyles={{ marginTop: '-10px' }}
                          customUserPadding={3}
                        />
                        <StatusIcon status={data.state} avatarSize={32} />
                      </Grid>
                      <Grid
                        item
                        container
                        sx={{
                          padding: '24px 10px 10px 15px',
                          backgroundColor: (t) => (t.palette.mode === 'dark' ? t.palette.primary.main : grey[200]),
                          borderRadius: '10px',
                          margin: '6px',
                        }}
                      >
                        <Typography>{data.comment}</Typography>
                      </Grid>
                      <Grid item container>
                        <Typography color="textSecondary" variant="subtitle2" sx={{ fontSize: 12, marginLeft: '15px' }}>
                          {formatDate(data.createdAt, 'hh:mm a DD/MM/YYYY')}
                        </Typography>
                      </Grid>
                      {data?.signature && (
                        <Grid
                          container
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems="center"
                          sx={{ margin: '5px 0px' }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>Signature</Typography>
                          <Paper
                            variant="outlined"
                            sx={{ p: 1, borderRadius: 1, display: 'flex', alignItems: 'center' }}
                          >
                            <img
                              style={{
                                width: '100%',
                                height: 75,
                                objectFit: 'contain',
                                userSelect: 'none',
                                pointerEvents: 'none',
                                verticalAlign: 'middle',
                              }}
                              src={data?.signature?.base64Data}
                              alt="signature"
                            />
                          </Paper>
                        </Grid>
                      )}
                      {data?.signature && (
                        <Grid item container justifyContent="flex-end">
                          <Typography color="textSecondary" variant="subtitle2" sx={{ fontSize: 12 }} align="right">
                            {data?.signature && 'Signed '} {formatDate(data?.createdAt, 'hh:mm a DD/MM/YYYY')}{' '}
                            {getSignatureName(data)}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )),
                )}
              </Grid>
            </Grid>
          )}
          <Grid
            item
            sm={12}
            component={Paper}
            sx={{
              borderRadius: '22px',
              boxShadow: '0px 0px 10px rgba(0,0,0,0.16)',
              padding: '20px',
              marginRight: '20px',
              marginBottom: '20px',
            }}
            container
            direction="column"
          >
            <Grid container>
              <Typography variant={'h5'} sx={{ fontWeight: 500 }}>
                Comments
              </Typography>
            </Grid>
            <Divider sx={{ margin: '5px 0px' }} />
            <CommentsSection
              loading={revFetching}
              comments={revComments}
              filterPosition={'flex-end'}
              disabled={true}
              fullWidth={true}
              versionSelector={false}
              variant={'v2'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default IssueInfo;
