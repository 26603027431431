import axios from '../../../../../redux/apiCaller';
import { openPDFDialog } from '../../../../Global/PDFViewer/dialog';
import { selectFile } from '../../../../../redux/actions';

import StateManager from '../../../../Global/StateManager';
import { BASE_URL } from '../../../../../constants';

import cloneDeep from 'lodash/cloneDeep';

export const previewTemplateFile = (id, category = 'normal', setLoading) => {
  const thunk = async function thunk(dispatch) {
    let base = category === 'normal' ? category : 'q';
    const url = `dochub/${base}Templates/${id}`;
    try {
      const response = await axios.get(url);
      if (response.data?.result) {
        const result = cloneDeep(response.data.result);
        if (category === 'normal') {
          const selected = result.file;
          selected.location = await getDecryptedFile(result);
          const extension = selected.mimetype && selected.mimetype.split('/', 2)[1];
          if (extension === 'pdf') {
            openPDFDialog({
              location: selected.location,
              name: selected.name ? selected.name : selected.originalname,
              id: selected?._id,
            });
          } else {
            dispatch(
              selectFile({
                file: { ...selected, title: selected.name ? selected.name : selected.originalname },
                state: true,
              }),
            );
          }
          setLoading(null);
        }
        if (category === 'qhub') {
          const fileObject = { title: result.title, content: result.raw, hasToc: result.hasToc, category: 'qhub' };
          dispatch(
            selectFile({
              file: fileObject,
              state: true,
            }),
          );
          setLoading(null);
        }
      } else {
        setLoading(null);
        StateManager.setErrorAlert('We can not find this template');
      }
    } catch (err) {
      setLoading(null);
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};

export const getDecryptedFile = async (document, property = 'file') => {
  try {
    if (document?.encrypted) {
      const token = localStorage.getItem('token');
      const selected = document[property];
      const body = await axios.post('uploader/getObject', { file: selected });
      if (body.data?.url) {
        const url = body.data?.url;
        return `${BASE_URL}${url}?user_token=${token}`;
      }
    } else {
      return document?.file?.location;
    }
  } catch (error) {
    StateManager.setAxiosErrorAlert(error);
    return null;
  }
};

export const previewFile = (document, fullScreen = false) => {
  const thunk = async function thunk(dispatch) {
    try {
      if (document) {
        const clonedDocument = cloneDeep(document);
        const category = clonedDocument?.category;
        if (category === 'normal') {
          let selected = clonedDocument.file;
          selected.location = await getDecryptedFile(clonedDocument);
          const extension = selected.mimetype && selected.mimetype.split('/', 2)[1];
          if (extension === 'pdf') {
            openPDFDialog({
              location: selected.location,
              name: selected.name ? selected.name : selected.originalname,
              id: selected?._id,
            });
          } else {
            dispatch(
              selectFile({
                file: { ...selected, title: selected.name ? selected.name : selected.originalname },
                state: true,
              }),
            );
          }
        }
        if (category === 'qhub') {
          let fileObject = {
            title: clonedDocument.title,
            content: clonedDocument.sections,
            hasToc: clonedDocument.hasToc,
            category: 'qhub',
          };
          if (fullScreen) {
            fileObject = {
              ...fileObject,
              defaultHeaderFooter: clonedDocument.defaultHeaderFooter,
              headerConfig: clonedDocument.headerConfig,
              footerConfig: clonedDocument.footerConfig,
              owner_id: clonedDocument.owner_id,
            };
          }
          dispatch(
            selectFile({
              file: fileObject,
              state: true,
              fullScreen,
            }),
          );
        }
      } else {
        StateManager.setErrorAlert('We can not find this document');
      }
    } catch (err) {
      StateManager.setAxiosErrorAlert(err);
    }
  };
  return thunk;
};
