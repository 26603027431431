import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress, Button } from '@mui/material';
import { EditRounded } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { PortalIcon } from '../Icons';
import { Link } from 'react-router-dom';
import { blue } from '@mui/material/colors';
import { isFunction, isEmpty } from 'lodash';
import StateManager from '../StateManager';
import { SearchMenu } from './index';
import axios from 'axios';

export default function PortalSpaceReference({ portalSpaceId, editable, onChange, typographyProps, ...props }) {
  const [loadingSpace, setLoadingSpace] = useState(false);
  const [data, setData] = useState(null);
  const [spaces, setSpaces] = useState([]);
  const [loadingSpaces, setLoadingSpaces] = useState(false);
  const [anchor, setAnchor] = useState(null);

  const { user } = useSelector(({ profile }) => profile);

  const loadPortalSpaces = anchor && isEmpty(spaces);

  useEffect(() => {
    if (!loadPortalSpaces) return;

    setLoadingSpaces(true);

    axios
      .get('/portal/user/getPortalSpacesForLinks')
      .then(({ data }) => {
        setSpaces(data);
      })
      .catch((err) => {
        StateManager.setAxiosErrorAlert(err);
      })
      .finally(() => {
        setLoadingSpaces(false);
      });
  }, [loadPortalSpaces]);

  useEffect(() => {
    if (!portalSpaceId) {
      setData(null);
      return;
    }
    setLoadingSpace(true);

    axios
      .get('/portal/user/getPortalSpaceInfo', { params: { portalSpaceId } })
      .then(({ data }) => {
        setData(data);
        setLoadingSpace(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingSpace(false);
      });
  }, [portalSpaceId]);

  if (!loadingSpace && !data && !editable) return null;

  return (
    <Grid container alignItems={'center'} wrap="nowrap" {...props}>
      {loadingSpace ? (
        <CircularProgress />
      ) : data ? (
        <>
          <PortalIcon />

          <Typography sx={{ ml: 2, fontSize: 18 }} {...typographyProps}>
            This entry was created in portal space{' '}
            <Link to={user.access === 'portal' ? `/portal/space/${data._id}` : `/portal/management/space/${data._id}`}>
              <span style={{ fontWeight: 500, color: blue[500] }}>{data.title}</span>
            </Link>
          </Typography>
        </>
      ) : (
        <>
          <PortalIcon />

          <Typography sx={{ ml: 2, fontSize: 18 }} {...typographyProps}>
            This entry hasn't been assigned a portal space
          </Typography>
        </>
      )}
      {editable && (
        <>
          <Button onClick={(e) => setAnchor(e.currentTarget)} sx={{ ml: 'auto' }} startIcon={<EditRounded />}>
            change
          </Button>

          <SearchMenu
            anchorEl={anchor}
            open={Boolean(anchor)}
            onClose={() => setAnchor(null)}
            items={spaces}
            loading={loadingSpaces}
            textField="title"
            idField="_id"
            showClear
            onResult={(id) => isFunction(onChange) && onChange(id)}
            onClear={() => isFunction(onChange) && onChange(null)}
            map={(x) => x._id}
          />
        </>
      )}
    </Grid>
  );
}
