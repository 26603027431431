import { useEffect, useState, useMemo } from 'react';
import { grey, green, orange, blue, deepOrange, red, amber } from '@mui/material/colors';
import { DialogContent, DialogTitle, Grid, Typography, CircularProgress, InputBase, Chip } from '@mui/material';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import UserInput from '../../../Global/DataGrid/Filters/UserInput';
import { UserGroup } from '../../../Global/Components';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Tooltip, Tabs, Tab, IconButton } from '@mui/material';
import { CheckCircleRounded, DeleteOutlineRounded, CancelRounded, EmojiPeopleRounded } from '@mui/icons-material';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import SendAndArchiveRoundedIcon from '@mui/icons-material/SendAndArchiveRounded';
import { SearchRounded, CloseRounded, ContentCopy, MoreVert } from '@mui/icons-material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import OutboxRoundedIcon from '@mui/icons-material/OutboxRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { RoundedDialog, UserChip, StandardDialogActions, GeneralButton } from '../../../Global/Components';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { FormatDate } from '../../../Global/Functions';
import QRCodeComp from '../../../Global/QRCode';
import StateManager from '../../../Global/StateManager';
import UsersDialog from '../../../Global/UsersDialog';
import PrefillFieldsDialog from './PrefillFieldsDialog';
import { BASE_URL } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import { useDataGridPagination } from '../../../Global/hooks';
import OptionsInput from '../../../Global/DataGrid/Filters/OptionsInput';
import axios from 'axios';
import moment from 'moment';
import { isArray } from 'lodash';
import NewSurveyDialog from './NewSurveyDialog';
import SendExternalDialog from './SendExternalDialog';
import { styled } from '@mui/material/styles';

const TabHeader = styled(Tab)(() => ({
  px: 1.5,
  py: 0.5,
  minWidth: 140,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  minHeight: 48,
}));

const EntryStateColors = {
  Pending: grey[500],
  Working: blue[500],
  Overdue: red[600],
  Completed: green[500],
  Approval: amber[600],
  'Approval rejected': red[600],
};

const StateOptions = Object.keys(EntryStateColors).map((x) => ({
  _id: x,
  title: x,
}));

export default function SurveyHistoryDialog({ open, onClose, form }) {
  const [surveys, setSurveys] = useState([]);
  const [survey, setSurvey] = useState();
  const [loading, setLoading] = useState(true);
  const [surveyDialog, setSurveyDialog] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [newSurveyDialog, setNewSurveyDialog] = useState(false);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  useEffect(() => {
    if (!open || !form._id) return;
    setLoading(true);
    axios
      .get('/forms/invitations/getSurveys', { params: { formId: form._id } })
      .then((res) => {
        setSurveys(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [open, form]);

  const cols = [
    { field: 'title', headerName: 'Title', flex: 2 },
    {
      field: 'pendingInvitationsCount',
      headerName: 'Pending',
      renderCell: ({ row }) => (
        <Typography m={0} width={'100%'} textAlign={'center'} color={grey[600]}>
          {row.pendingInvitationsCount}
        </Typography>
      ),
    },
    {
      field: 'workingEtriesCount',
      headerName: 'In Work',
      renderCell: ({ row }) => (
        <Typography m={0} width={'100%'} textAlign={'center'} color={orange[400]}>
          {row.workingEtriesCount}
        </Typography>
      ),
    },
    {
      field: 'completed',
      headerName: 'Completed',
      renderCell: ({ row }) => (
        <Typography m={0} width={'100%'} textAlign={'center'} color={green[400]}>
          {row.completedEtriesCount}
        </Typography>
      ),
    },
    { field: 'createdAt', headerName: 'Created At', valueFormatter: (row) => FormatDate(row.value), flex: 2 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      renderCell: (row) =>
        row.row.suspended ? (
          <Chip label={'Suspended'} color={'error'} />
        ) : row.value === 'draft' ? (
          <Chip label={'Draft'} color={'default'} />
        ) : (
          <Chip label={'Live'} color={'success'} />
        ),
    },
    {
      field: 'id',
      headerName: 'QR-Code',
      flex: 1,
      renderCell: (row) => {
        return (
          !row.row.suspended &&
          row.row.status === 'live' && (
            <QRCodeComp linkType={'form_survey'} linkId={row.id} sx={{ mx: 1 }} title={survey?.title} />
          )
        );
      },
    },
  ];
  const filteredSurveys = surveys?.filter((survey) => survey?.title?.toLowerCase().includes(filterText?.toLowerCase()));

  function handleClick(row) {
    setSurvey(row.row);
    setSurveyDialog(true);
  }

  function handleChange(newValue) {
    if (newValue.deletedId) {
      setSurveys([...surveys.filter((survey) => survey._id !== newValue.deletedId)]);
      return;
    }
    setSurveys([...surveys.filter((survey) => survey._id !== newValue._id), newValue]);
  }

  return (
    <RoundedDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      maxHeight="md"
      fullWidth
      className={'scroll-bar'}
      minHeight={'80vh'}
    >
      <DialogTitle>
        <Grid container direction={'row'} justifyContent={'space-between'} fullWidth>
          {'Surveys'}
          <InputBase
            placeholder="Search Surveys"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            startAdornment={<SearchRounded sx={{ mr: 1 }} />}
            autoFocus
            endAdornment={
              filterText && (
                <CloseRounded
                  sx={{ cursor: 'pointer', mx: 1 }}
                  onClick={() => {
                    setFilterText('');
                  }}
                />
              )
            }
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container alignItems="center" justifyContent="center" style={{ height: '20vh' }}>
            <CircularProgress color="primary" />
          </Grid>
        ) : (
          <>
            <Grid container minHeight="40vh" alignItems="center" sx={{ height: '100%' }}>
              {filteredSurveys.length === 0 ? (
                <Typography
                  width="100%"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  textAlign="center"
                  color="textSecondary"
                >
                  No surveys found
                </Typography>
              ) : (
                <Grid item container style={{ height: 520 }}>
                  <DataGridPremium
                    columns={cols}
                    rows={filteredSurveys}
                    getRowId={(row) => row._id}
                    pagination
                    rowHeight={40}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onRowClick={(row) => handleClick(row)}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    disableRowSelectionOnClick
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <SurveyDialog
        survey={survey}
        open={surveyDialog}
        setOpen={setSurveyDialog}
        setClose={() => setSurveyDialog(false)}
        handleChange={handleChange}
      />
      <StandardDialogActions
        additionalActions={
          <GeneralButton
            onClick={() => setNewSurveyDialog(true)}
            startIcon={<EmojiPeopleRounded />}
            style={{ marginRight: 'auto' }}
          >
            Create survey
          </GeneralButton>
        }
        onClose={onClose}
      />
      <NewSurveyDialog
        open={newSurveyDialog}
        onClose={(data) => {
          setSurveys((prev) => [...prev, data]);
          setNewSurveyDialog(false);
        }}
        form={form}
      />
    </RoundedDialog>
  );
}

export function SurveyDialog({ survey, open, setOpen, setClose, handleChange }) {
  const [suspended, setSuspended] = useState(false);
  const [surveyData, setSurveyData] = useState();
  const [loading, setLoading] = useState(false);
  const [prefilledDialog, setPrefilledDialog] = useState(false);
  const [status, setStatus] = useState();
  const [openUsersDialog, setOpenUsersDialog] = useState(false);
  const [sendExternalDialog, setSendExternalDialog] = useState(false);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [downloadingExcel, setDownloadingExcel] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(false);
  const [isAnonymized, setIsAnonymized] = useState(false);

  const [tab, setTab] = useState('internal');

  useEffect(() => {
    if (!survey) return undefined;
    setSuspended(Boolean(survey.suspended));
    setSurveyData(survey);
    setStatus(survey.status);
    setIsAnonymized(survey.surveySettings ? survey.surveySettings.anonymizationOfUsers : false);
  }, [survey]);

  function suspendSurvey() {
    const surveyId = survey._id;
    axios
      .post('/forms/invitations/suspendResumeSurvey', { surveyId })
      .then((res) => {
        setSurveyData(res.data);
        handleChange(res.data);
        setSuspended(res.data.suspended);
        setLoading(false);
      })
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }

  function confirmDeleteSurvey() {
    StateManager.setConfirm('You are about to delete this survey', deleteSurvey);
  }

  function deleteSurvey() {
    const surveyId = survey._id;
    axios
      .post('/forms/invitations/deleteSurvey', { surveyId })
      .then((res) => {
        setOpen(false);
        handleChange(res.data);
        StateManager.setSuccessAlert('Survey deleted successfully');
      })
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }

  function editSurvey(section) {
    const surveyId = survey._id;
    const body = {
      surveyId,
      title: survey.title,
      status: survey.status,
      formPreFilledSections: section,
    };
    axios.post('/forms/invitations/editSurvey', body).then((res) => {
      setPrefilledDialog(false);
    });
  }

  function saveSettings(settingType, setting) {
    const surveyId = survey._id;
    axios
      .post('/forms/invitations/editSurveySettings', { surveyId, settingType, setting })
      .then((res) => {
        StateManager.setSuccessAlert('Survey settings updated successfully');
      })
      .catch((err) => StateManager.setAxiosErrorAlert(err));
  }

  function releaseSurvey(survey) {
    axios.post('/forms/invitations/releaseSurvey', { surveyId: survey }).then((res) => {
      setSurveyData(res.data);
      setStatus('live');
      handleChange(res.data);
      StateManager.setSuccessAlert('Survey released successfully');
    });
  }

  function selectTab(selected) {
    setTab(selected);
  }

  const internalEntriesColumns = useMemo(() => {
    const usersOnlyOperators = [
      {
        label: 'Includes',
        value: 'includes',
        InputComponent: UserInput,
        InputComponentProps: { type: 'user' },
        getValueAsString: (value) => (isArray(value) ? value.join(', ') : ''),
      },
    ];

    return [
      {
        field: 'number',
        headerName: '#',
        type: 'number',
        width: 66,
        renderCell: ({ row }) => (
          <Typography style={{ fontSize: 14 }}>
            {row.number}
            {''}
          </Typography>
        ),
      },
      {
        headerName: 'Version',
        width: 80,
        type: 'number',
        field: 'version',
        renderCell: ({ row }) => (
          <Grid container alignItems="center">
            {row.testModeId && (
              <Chip label={'Test'} size="small" sx={{ background: deepOrange[700], color: 'white', mr: 1 }} />
            )}
            <Typography style={{ fontSize: 14 }}>{`v${row.version}${row.testVersion ? '.' : ''}${
              row.testVersion || ''
            }`}</Typography>
          </Grid>
        ),
      },
      {
        field: 'createdAt',
        type: 'date',
        width: 150,
        headerName: 'Started',
        valueGetter: ({ row }) => (row.createdAt ? moment(row.createdAt).toDate() : null),
        valueFormatter: ({ value }) => FormatDate(value),
      },
      {
        width: 250,
        headerName: 'Assigned users',
        field: 'assignedUsers',
        sortable: false,
        filterOperators: usersOnlyOperators,
        renderCell: ({ row }) => <UserGroup ids={row.assignedUsers} avatarSize={32} />,
      },
      {
        field: 'completedAt',
        type: 'date',
        width: 150,
        headerName: 'Completed',
        valueGetter: ({ row }) => (row.completedAt ? moment(row.completedAt).toDate() : null),
        valueFormatter: ({ value }) => (value ? FormatDate(value) : '-'),
      },
      {
        width: 100,
        headerName: 'Status',
        field: 'state',
        renderCell: ({ value, row }) => (
          <Chip
            label={row.archivedAt ? 'Archived' : value}
            size="small"
            sx={{ background: row.archivedAt ? '' : EntryStateColors[value], color: 'white' }}
          />
        ),
        filterOperators: [
          {
            label: 'Includes',
            value: 'includes',
            InputComponent: OptionsInput,
            InputComponentProps: { options: StateOptions },
          },
        ],
      },
    ];
  }, []);

  const externalEntriesColumns = useMemo(() => {
    return [
      {
        field: 'invitationSentAt',
        type: 'date',
        width: 200,
        headerName: 'Invitation sent',
        valueGetter: ({ row }) => (row.invitationSentAt ? moment(row.invitationSentAt).toDate() : null),
        valueFormatter: ({ value }) => FormatDate(value),
      },
      {
        field: 'invitationName',
        width: 150,
        headerName: 'Name',
      },
      {
        field: 'invitationEmail',
        width: 150,
        headerName: 'Email',
      },
      {
        field: 'createdAt',
        type: 'date',
        width: 150,
        headerName: 'Entry started',
        valueGetter: ({ row }) => (row.createdAt ? moment(row.createdAt).toDate() : null),
        valueFormatter: ({ value }) => (value ? FormatDate(value) : '-'),
      },
      {
        field: 'completedAt',
        type: 'date',
        width: 150,
        headerName: 'Entry completed',
        valueGetter: ({ row }) => (row.completedAt ? moment(row.completedAt).toDate() : null),
        valueFormatter: ({ value }) => (value ? FormatDate(value) : '-'),
      },
      {
        width: 100,
        headerName: 'Status',
        field: 'state',
        renderCell: ({ value, row }) => (
          <Chip
            label={row.archivedAt ? 'Archived' : value}
            size="small"
            sx={{ background: row.archivedAt ? '' : EntryStateColors[value], color: 'white' }}
          />
        ),
        filterOperators: [
          {
            label: 'Includes',
            value: 'includes',
            InputComponent: OptionsInput,
            InputComponentProps: { options: StateOptions },
          },
        ],
      },
      {
        field: '_id',
        width: 70,
        headerName: 'Link',
        renderCell: ({ row }) => (
          <Grid item>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(`${window.location.origin}/external/form/${row._id}`);
                StateManager.setSuccessAlert('Link successfully copied');
              }}
            >
              <ContentCopy />
            </IconButton>
          </Grid>
        ),
      },
    ];
  }, []);

  const history = useHistory();
  const configClass = survey ? `survey-${survey._id}-entries` : null;
  const tableConfigExternal = useDataGridPagination(
    configClass,
    externalEntriesColumns,
    '/forms/invitations/getSurveyInvitationsTable',
    'entries',
    'totalCount',
    'personal',
    {
      requestData: { surveyId: survey?._id },
      pinnedRight: ['state', '_id'],
    },
  );

  const tableConfigInternal = useDataGridPagination(
    configClass,
    internalEntriesColumns,
    '/forms/invitations/getInternalSurveyEntriesTable',
    'entries',
    'totalCount',
    'personal',
    {
      requestData: { surveyId: survey?._id },
      pinnedRight: ['state'],
    },
  );

  function sendInternally(data) {
    const body = {
      surveyId: survey._id,
      users: data.users,
      groups: data.groups,
    };

    axios.post('/forms/entries/startSurveyEntryForAppUsers', body).then((response) => {
      StateManager.setSuccessAlert('Survey successfully started');
      tableConfigInternal.reloadCurrentPage();
    });
  }
  let surveyInProcess = 0;
  let surveyDone = 0;
  tableConfigInternal.getRows().map((row) => {
    row.state === 'Working' ? (surveyInProcess += 1) : row.state === 'Completed' ? (surveyDone += 1) : null;
  });
  tableConfigExternal.getRows().map((row) => {
    row.state === 'Working' || row.state === 'Pending'
      ? (surveyInProcess += 1)
      : row.state === 'Completed'
      ? (surveyDone += 1)
      : null;
  });

  async function downloadPDFsAsZIP() {
    setDownloadingPDF(true);
    try {
      const response = await axios.get('/forms/management/getSurveyformEntriesAsPdfs', {
        params: { id: survey._id },
      });
      window.open(`${BASE_URL}${response.data.link}`, '_blank');
    } catch (error) {
      StateManager.setAxiosErrorAlert(`${error}`);
      console.error('Error fetching PDF links:', error);
    } finally {
      setDownloadingPDF(false);
    }
  }

  async function downloadExcel() {
    setDownloadingExcel(true);
    const body = {
      surveyId: survey._id,
    };

    try {
      const response = await axios.post('/forms/entries/surveyEntriiesAsTableToExcel', body);

      if (response.data.link) {
        window.open(`${BASE_URL}${response.data.link}`, '_blank');
      } else {
        console.error('No link found in response data');
        StateManager.setAxiosErrorAlert('No link found in response data');
      }
    } catch (error) {
      StateManager.setAxiosErrorAlert(`Error fetching Excel link: ${error}`);
      console.error('Error fetching Excel link:', error);
    } finally {
      setDownloadingExcel(false);
    }
  }

  const TableInternal = (
    <Grid container direction={'column'} sx={{ flexGrow: 1, position: 'relative' }}>
      <Grid container sx={{ flexGrow: 1, position: 'absolute', height: '100%' }}>
        <DataGridPremium
          display={'flex'}
          disableAggregation
          disableRowGrouping
          onRowClick={({ row }) => row?._id && history.push(`/forms/entry/${row._id}`)}
          fillHeight
          {...tableConfigInternal.gridProps}
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );

  const TableExternal = (
    <Grid container direction={'column'} sx={{ flexGrow: 1, position: 'relative' }}>
      <Grid container sx={{ flexGrow: 1, position: 'absolute', height: '100%' }}>
        <DataGridPremium
          display={'flex'}
          disableAggregation
          disableRowGrouping
          onRowClick={({ row }) => row?.entryId && history.push(`/forms/entry/${row.entryId}`)}
          fillHeight
          {...tableConfigExternal.gridProps}
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );

  if (!survey) return;
  return (
    <RoundedDialog
      open={open}
      loading={loading}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      fullWidth
      className="scroll-bar"
    >
      <DialogTitle>
        <Grid container direction={'row'} justifyContent={'space-between'} wrap="nowrap">
          <Grid container alignItems="center" justifyContent="flex-start" direction={'row'} gap={1}>
            {surveyData?.title}
            <Tooltip title={suspended ? 'Survey suspended' : 'Survey is active'} placement="top">
              {suspended ? (
                <CancelRounded style={{ color: red[500] }} />
              ) : (
                <CheckCircleRounded style={{ color: green[500] }} />
              )}
            </Tooltip>
            {isAnonymized && <Chip size="small" label="Anonymous" />}
          </Grid>
          {!suspended && surveyData?.status === 'live' && (
            <QRCodeComp linkType={'form_survey'} linkId={surveyData?._id} sx={{ mx: 1 }} title={surveyData?.title} />
          )}
          {(surveyInProcess || surveyDone) && (
            <>
              <Tooltip title="Download as XLS">
                <IconButton onClick={downloadExcel}>
                  {downloadingExcel ? <CircularProgress size={24} /> : <DownloadRoundedIcon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Download PDFs as ZIP">
                <IconButton onClick={downloadPDFsAsZIP}>
                  {downloadingPDF ? <CircularProgress size={24} /> : <PictureAsPdfRoundedIcon />}
                </IconButton>
              </Tooltip>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ height: '70vh' }}>
        <Grid item container direction={'column'} sx={{ height: '100%' }} wrap="nowrap">
          <Grid container direction={'column'}>
            <Grid item container alignItems="center">
              {loading ? (
                <CircularProgress color="primary" size={30} style={{ margin: 5 }} />
              ) : (
                <>
                  {(surveyData?.status === 'live' || suspended) && (
                    <GeneralButton
                      startIcon={
                        suspended ? (
                          <CheckCircleRounded style={{ color: green[500] }} />
                        ) : (
                          <CancelRounded style={{ color: red[500] }} />
                        )
                      }
                      onClick={suspendSurvey}
                    >
                      {suspended ? 'Resume' : 'Suspend'} survey
                    </GeneralButton>
                  )}
                  <GeneralButton
                    style={{ marginLeft: 16 }}
                    onClick={confirmDeleteSurvey}
                    startIcon={<DeleteOutlineRounded style={{ color: red[500] }} />}
                  >
                    Delete survey
                  </GeneralButton>
                  {survey.status === 'draft' && (
                    <GeneralButton
                      style={{ marginLeft: 16 }}
                      onClick={() => setPrefilledDialog(true)}
                      startIcon={<DriveFileRenameOutlineRoundedIcon style={{ color: grey[400] }} />}
                    >
                      Edit survey
                    </GeneralButton>
                  )}
                  {status === 'draft' && (
                    <GeneralButton
                      style={{ marginLeft: 16 }}
                      onClick={() => releaseSurvey(survey._id)}
                      startIcon={<OutboxRoundedIcon style={{ color: grey[400] }} />}
                    >
                      Release survey
                    </GeneralButton>
                  )}
                  {status === 'draft' && (
                    <PrefillFieldsDialog
                      open={prefilledDialog}
                      onClose={() => setPrefilledDialog(false)}
                      onDone={editSurvey}
                      surveyId={survey._id}
                      editable={true}
                    />
                  )}
                  {status !== 'draft' && !suspended && (
                    <GeneralButton
                      style={{ marginLeft: 16 }}
                      onClick={() => setPrefilledDialog(true)}
                      startIcon={<VisibilityRoundedIcon />}
                    >
                      Preview fields
                    </GeneralButton>
                  )}
                  {status !== 'draft' && (
                    <PrefillFieldsDialog
                      open={prefilledDialog}
                      onClose={() => setPrefilledDialog(false)}
                      surveyId={survey._id}
                      editable={false}
                    />
                  )}
                  {status === 'live' && !suspended && (
                    <>
                      <GeneralButton
                        style={{ marginLeft: 16 }}
                        onClick={() => setOpenUsersDialog(true)}
                        startIcon={<SendAndArchiveRoundedIcon />}
                      >
                        Send internally
                      </GeneralButton>
                      <GeneralButton
                        style={{ marginLeft: 16 }}
                        onClick={() => setSendExternalDialog(true)}
                        startIcon={<SendRoundedIcon />}
                      >
                        Send externally
                      </GeneralButton>

                      {surveyData?.link && (
                        <GeneralButton
                          style={{ marginLeft: 16 }}
                          onClick={() => {
                            navigator.clipboard.writeText(surveyData.link);
                            StateManager.setSuccessAlert('Link successfully copied');
                          }}
                          startIcon={<ContentCopy />}
                        >
                          Copy survey link
                        </GeneralButton>
                      )}
                      <Grid item>
                        <GeneralButton onClick={(e) => setMenuAnchor(e.currentTarget)} startIcon={<MoreVert />}>
                          More
                        </GeneralButton>

                        <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={() => setMenuAnchor(null)}>
                          <MenuItem
                            onClick={() => {
                              setIsAnonymized(!isAnonymized);
                              saveSettings('anonymizationOfUsers', !isAnonymized);
                            }}
                          >
                            <ListItemIcon>
                              {isAnonymized ? <PersonOutlineRoundedIcon /> : <PersonRoundedIcon />}{' '}
                            </ListItemIcon>
                            <ListItemText>{isAnonymized ? 'Make not anonymous' : 'Make anonymous'}</ListItemText>
                          </MenuItem>
                        </Menu>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>

            {suspended && (
              <Grid item container alignItems="center" style={{ marginTop: 16 }}>
                <Typography variant="h6" style={{ color: red[500] }}>
                  This survey has been suspended
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item container direction={'column'} height={'100%'} wrap="nowrap">
            <Grid item container>
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={tab}
                onChange={(e, value) => {
                  selectTab(value);
                }}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ mb: 1 }}
              >
                <TabHeader
                  label={
                    <>
                      Internal surveys entries <Chip sx={{ mx: 1 }} label={tableConfigInternal.gridProps.rowCount} />
                    </>
                  }
                  value="internal"
                />
                <TabHeader
                  label={
                    <>
                      External surveys entries <Chip sx={{ mx: 1 }} label={tableConfigExternal.gridProps.rowCount} />
                    </>
                  }
                  value="external"
                />
              </Tabs>
            </Grid>
            {tab === 'internal' && (
              <Grid container height={'100%'}>
                {TableInternal}
              </Grid>
            )}
            {tab === 'external' && (
              <Grid item container height={'100%'}>
                {TableExternal}
              </Grid>
            )}
          </Grid>
        </Grid>
        <SendExternalDialog
          surveyId={surveyData?._id}
          open={sendExternalDialog}
          onClose={() => setSendExternalDialog(false)}
          onResult={() => {
            tableConfigExternal.reloadCurrentPage();
          }}
        />
        <UsersDialog
          open={openUsersDialog}
          onClose={(data) => {
            if (data) {
              sendInternally(data);
            }
            setOpenUsersDialog(false);
          }}
          showGroups
        />
      </DialogContent>
      <StandardDialogActions
        additionalActions={
          <>
            <Typography sx={{ textWrap: 'nowrap' }}>{`Created ${FormatDate(surveyData?.createdAt)} by`}</Typography>
            <Grid item container alignItems="center">
              <UserChip margin={0} id={surveyData?.owner} size="small" />
            </Grid>
            <Grid mr={'auto'} />
          </>
        }
        onClose={() => {
          handleChange(surveyData);
          setOpen({ ...surveyData, surveyInProcess, surveyDone });
          setClose(false);
        }}
      />
    </RoundedDialog>
  );
}
